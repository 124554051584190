import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import NewFormProduct from '../../components/NewFormProduct';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import LoadingComponent from '../../components/LoadingComponent';
import { GlobalContext } from '../../contexts/GlobalContext';
import { Card, CardMedia, Chip, Typography } from '@mui/material';
import Fancybox from '../../components/Fancybox';

function CatalogManage() {
    let { id } = useParams();
    const { cdn, url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, ledgers  } = useContext(GlobalContext);
    const [first, setFirst] = useState(true);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("Catalog");
    const [items, setItems] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [deleteId, setDeleteItem] = useState([]);

    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
    }, []);
  
    const fetchData = () => {
        var page = currentPage + 1;
        axios.post( url + 'api/suppliers/chat/forms/products?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            form: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems((prev) => [...prev, ...response.data.products.data]);
            setTitle(response.data.form.name);
            setSizes(response.data.sizes);
            setCurrentPage(parseInt(response.data.products.current_page));
            if(response.data.products.current_page == response.data.products.last_page) {
                setHasMore(false);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    function Item (prop) {
        const removeData = (id, index) => {
            if(confirm(`Confirm deletion of the product: ${prop?.data?.name}?`)) {
                axios.post( url + 'api/suppliers/chat/forms/products/delete', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    id: id,
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    if(response?.data?.error == 0){
                        window.location.reload();
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }

        var images = JSON.parse(prop.data.image);

        return (
            <Card sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                {(images != null && images?.length > 0) ? 
                        <Fancybox options={{ Carousel: { infinite: false } }}>
                            <CardMedia component="div" sx={{ height: 180, position: 'relative' }}>
                                <img
                                src={`${cdn}catalog/${images[0]?.file}`}
                                alt="Product Image"
                                data-fancybox={"gallery_name"}
                                href={`${cdn}catalog/${images[0].file}`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'fill'
                                }}
                                />
                            </CardMedia>
                        </Fancybox>
                        : 
                        <Box sx={{height: 180, background: "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography variant="caption">No Image</Typography>
                        </Box>
                }
                <CardContent sx={{ borderBottom: 1, borderColor: 'divider', py:1}}>
                    <Typography variant="body2" color="text.secondary" component="div" sx={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{prop.data.name}</Typography>
                    {prop?.data?.sizes && 
                        <Chip label={ <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>{prop?.data?.sizes?.name}</Typography>} color="default" size="small" sx={{ marginTop: "5px" }} />                    
                    }
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'center', gap: "10px" }}>
                    <NewFormProduct type="Edit" category={id} sizes={sizes} data={prop.data}><IconPencil /></NewFormProduct>
                    <Button size="small" color="error" onClick={() => { removeData(prop.data.id, prop.index) }}><IconTrash /></Button>
                </CardActions>
            </Card>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title={title}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                        <Grid container spacing={2}>                                
                            {items?.map((item, index) => (
                                <Grid item  xs={6} sm={4} md={3} lg={2} key={item?.id}>
                                    <Item data={item} index={index}></Item>
                                </Grid>
                            ))}
                        </Grid>
                    </InfiniteScroll>
                </Box>
                <NewFormProduct type="New" form={id} sizes={sizes} />
            </Layout>
        }
        </div>
    );
}

export default CatalogManage;
