import { Alert, AlertTitle, Button, Chip, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../components/BackToTop";
import ErrorMessage from "../../../components/ErrorMessage";
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import MediaGrid from "../../../components/MediaGrid";
import ScrollComponent from "../../../components/ScrollComponent";
import UploadButton from "../../../components/VoucherUploader";
import { FilterDataContext } from "../../../contexts/FilteredDataContext";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function FilteredPayments() {

    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, } = useContext(FilterDataContext);

    const { url, customer, token, portal  } = useContext(GlobalContext);

    const title = "Filtered Payments";

    const currentTab = { name: "Payments", status: { 1: "Pending", 2: "Completed", 3: "Voided", 99: "All Data",}};

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/payments?page=' + page, {
            customer: customer,
            buyer: portal == "buyer" ? customer : filterDataBuyer?.id,
            supplier: portal == "supplier" ? customer : filterDataSupplier?.id,
            status: filterDataStatus==="allData" ? "" : filterDataStatus.toString(),
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredPayments',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const Item = ({data}) => {
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                { filterDataStatus === "allData" && data?.status_label && 
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}> <Chip  label={data?.status_label}  /> </Box>
                }
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container sx={{ borderColor: 'divider' }}>
                    <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data?.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        {data?.total ? 
                            <Typography variant="body" component="p">&#8377; {data.total}</Typography> :
                            <Typography variant="body" component="p">{"-"}</Typography>
                        }
                    </Grid>
                    {!!data?.narration &&
                        <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.narration || "-"}</Typography>                        
                        </Grid>
                    }
                </Grid>
                <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir={data?.attachment_directory} images={data?.images} />
                </Box>
                {data?.status_label != "Voided" &&
                    <Box sx={{margin: "5px"}}>
                        <Button variant="contained" onClick={() => {navigate("/payment/" + data?.id)}} fullWidth>View Payments</Button>
                    </Box>
                }
            </Paper>
        )
    }

    if(!filterDataBuyer && !filterDataSupplier){
        navigate(-1, { replace: true })
        return null;
    }

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})
    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus})

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>            
            {  isLoading ? 
            <LoadingComponent loading={isLoading} />
            : 
            <Layout title={title} backToTop={true} >                
                <Box>
                    <Alert severity="success" >
                        <AlertTitle sx={{fontSize: "0.5 em"}}> Results {portal === "buyer" ? filterDataSupplier && `for ${(filterDataSupplier?.name?.toUpperCase())}` : filterDataBuyer && `for ${(filterDataBuyer?.name?.toUpperCase())}`}</AlertTitle>
                        {(filterDataFromDate && filterDataToDate) && 
                        ` From: ${format(new Date(filterDataFromDate), 'dd/MM/yyyy')} To: ${format(new Date(filterDataToDate), 'dd/MM/yyyy')}`
                        }    
                        <Box sx={{mt: 1}}>
                            { filterDataStatus == "allData" ? `Status: ${currentTab.status[99]}`: `Status: ${currentTab.status[filterDataStatus]}`}
                        </Box>
                    </Alert>                    
                </Box>                
                <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                <BackToTop alone={true} />
            </Layout>
            }
            </>
        }
        </>
    )
}