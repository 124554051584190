import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from "axios";
import { GlobalContext } from '../contexts/GlobalContext'
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fancybox from './Fancybox';
import { IconPlus, IconMinus, IconSend } from '@tabler/icons-react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { format, formatDistance, parse } from 'date-fns'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Pusher from 'pusher-js';
import { AudioRecorder } from 'react-audio-voice-recorder';
import './Audio.css';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import { useNavigate } from "react-router-dom";
import { DialogActions, IconButton, useMediaQuery } from '@mui/material';
import LoadingComponent from './LoadingComponent';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { LoadingButton } from '@mui/lab';

function Chatter(props) {
    const FloatDetails = props.FloatDetails;

    const [id, setId] = useState(props.id);
    const { url, cdn, wsHost, wsKey, wsPort, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, ledgers, portal } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
 
    const [channel, setChannel] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newMessages, setNewMessages] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);
    const scrollRef = useRef(null);

    const [showAudioModal, setShowAudioModal] = useState(false);
    const [audioSrc, setAudioSrc] = useState("");

    const navigate = useNavigate();
    
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)'); 

    useEffect(() => {
        const client = new Pusher(wsKey, {
			wsHost: wsHost,
            forceTLS: false,
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            // authEndpoint: url + 'api/broadcasting/auth',
            // auth: {
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Authorization': "Bearer " + token
            //     }
            // }
		})
        // const channel = client.subscribe('customers.' + customer);
        const channel = client.subscribe('chat.' + id);

        channel.bind("chat-event", function(data) {
            console.log("New Message", data);
            var msg = JSON.parse(data.message);
            setNewMessages(true);
            setMessages((prev) => [...prev, ...msg]);
        });


        channel.bind("pusher:subscription_succeded", function() {
            console.log("pusher:subscription_succeded");
        });

        channel.bind("pusher:subscription_error", function(error) {
            console.log("pusher:subscription_succeded", error);
        });

        setChannel([]);
        setMessages([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
    }, [ id ]);



    const fetchData = async () => {
        setIsLoading(true);
        var page = currentPage + 1;
        await axios.post( url + 'api/chat/channel/data?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            channel: id,
            portal: portal,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            setChannel(response.data.channel);
            console.log(response.data.messages.data);
            setMessages((prev) => [...prev, ...response.data.messages.data]);
            setCurrentPage(parseInt(response.data.messages.current_page));
            if(response.data.messages.current_page == response.data.messages.last_page) {
                setHasMore(false);
            }
            setLoading(false);
            setIsLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
        console.log("scrollRef.current", scrollRef.current)
        
    }

    useEffect(() => {
        console.log("scrollRef", scrollRef.current.scrollHeight);
        if (scrollRef.current) { // check if ref is not null
            window.scrollTo({
                top: scrollRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [ isLoading, messages ]);


    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    function stringAvatar(name) {
        var split = name.split(' ');
        return {
            sx: {
            bgcolor: stringToColor(name),
            width: 26,
            height : 26,
            fontSize: 12
            },
            children: `${split[0][0]}${split[1] ? split[1][0] : ''}`,
        };
    }

    const NewOrder = (prop) => {
        const [open, setOpen] = useState(false)
        const [form, setForm] = useState(null);
        const [formProducts, setFormProducts] = useState(null);
        const [tempData, setTempData] = useState([]);
        const [loading, setLoading] = useState(false);


        const handleOpen = () => {
            setOpen(true);
        }

        const handleClose = () => {
            setOpen(false);
            setForm(null);
            setFormProducts([]);
            setTempData([]);
        }

        const fetchData = () => {
            setLoading(true);
            axios.post( url + 'api/suppliers/chat/forms/data', {
                customer: customer,
                contact: contact,
                phone: phone,
                id: id,
                form: prop.form_id
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setForm(response.data.form);
                setFormProducts(response.data.products);
                handleOpen();
                setLoading(false);
            })
            .catch(function (error) {
                console.log({error});
            });
        }

        
        const handleSubmit = (event) => {
            event.preventDefault();

            if(!!tempData && tempData?.length > 0){

                setLoading(true);
                axios.post( url + 'api/buyers/chat/order', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    form: prop.form_id,
                    channel: channel.name,
                    buyer: channel.buyer,
                    seller: channel.seller,
                    creator: portal == "supplier" ? 2 : 3,
                    items: tempData
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    console.log({orderResponse: response})               
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
                
            }else{
                alert("No quantities have been specified; please add them.")
            }
           
        }

        const updateData = (id, size, qty) => {
            console.log('updateData called with', id, size, qty);
            setTempData(prevData => {
                const updatedData = prevData.reduce((acc, item) => {
                    if (item.id === id && item.size === size) {
                    if (qty > 0) {
                        acc.push({ ...item, qty });
                    }
                    } else {
                    acc.push(item);                 
                    }
                    return acc;
                }, []);

                if (!prevData.some(item => item.id === id && item.size === size) && qty > 0) {
                    console.log('New item created:', { id, size, qty });
                    updatedData.push({ id, size, qty });
                }
          
                return updatedData;
            });
        };

        const ProductQty = (prop) => {
            
            const [qty, setQty] = useState(() => {
                const existingItem = prop.tempData.find(item => item.id === prop.id && item.size === prop.size);
                return existingItem ? existingItem.qty : 0;
            });

            const updateQty = (mode) => {
                var newQty = parseFloat(qty);
                if (mode == "+") {
                  newQty = newQty + 1;
                } else if (mode == "-") {
                  newQty = newQty - 1;
                  if (newQty < 0) {
                    newQty = 0;
                  }
                }
                setQty(newQty);
                console.log("newQty", newQty);
                prop.updateData(prop.id, prop.size, newQty);
            };

            const handleQtyChange = (event) => {
                if(event?.target?.value > 0){
                    setQty(event?.target?.value)
                    prop.updateData(prop.id, prop.size, event?.target?.value);
                }
            }
              
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 2, my: 2 }}>
                    <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} variant="outlined" size="small" onClick={event => { updateQty("-"); }}>
                        <IconMinus />
                    </Button>
                    <TextField id="outlined-basic" label={prop.label} variant="outlined" size="small" value={qty} onChange={handleQtyChange} />
                    <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} variant="outlined" size="small" onClick={event => { updateQty("+"); }}>
                        <IconPlus />
                    </Button>
                </Box>
            )

        }

        const Product = (prop) => {

            const [productSizes, setProductSizes] = useState(() => {
                return prop?.data.sizes?.name.split(",").map((size) => ({ name: size, qty: 0 })) || [];
            });

            let images = JSON.parse(prop.data.image);

            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <CardMedia data-fancybox="order_form" href={ cdn + 'catalog/' + images[0].file } sx={{ height: 300, width: "100%", borderRadius: 1  }} image={cdn + 'catalog/' + images[0].file}/>
                        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', p: 2 }}>
                            <Typography display="block" variant="body2" sx={{fontWeight: "bold"}} gutterBottom>{prop.data.name.toUpperCase()}</Typography>
                            <Typography variant="caption" sx={{mb:2}}>Sizes available</Typography>
                            {prop?.data?.sizes ?
                                <>
                                    {productSizes.map((row, index) => (
                                        <ProductQty key={index} label={row.name.toUpperCase() } id={prop.data.id} size={row.name.toUpperCase() } tempData={tempData} updateData={updateData} />
                                    ))}
                                </>                                
                                :
                                <ProductQty label="Qty" id={prop.data.id} size={ null } tempData={tempData} updateData={updateData} />
                            }
                        </Box>
                    </Box>
                </Paper>
            )
        }

        return (
            <>
                <LoadingButton loading={loading} onClick={fetchData} variant="contained">Place Order</LoadingButton>
                <Dialog onClose={handleClose} open={open}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle sx={{fontWeight: "400"}}>Place Order</DialogTitle>
                    <DialogContent dividers={true} sx={{maxHeight: "80vh", overflow: "auto", width: '80vw', maxWidth: '400px'}} >
                        <Fancybox options={{ Carousel: { infinite: false } }}>
                            {formProducts?.map((row, index) => (
                                <Product key={row.id} data={row} index={index}></Product>
                            ))}
                        </Fancybox>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton loading={loading} type="submit" variant="contained" disabled={tempData?.length == 0}>Place</LoadingButton>
                    </DialogActions>
                </form>
                </Dialog>
            </>
        )
    }

    const Images = (prop) => {
        const timestamp = Date.now();
        const randomString = Math.random().toString(36).substring(2, 15);
        const gallery_name = `${timestamp}_${randomString}`;
        const commonImageExtensions = ["jpg", "jpeg", "png", "gif", "svg", "webp", "bmp", "tiff", "tif", "ico", "jxr"];
        const documentFormats = ['pdf', 'doc', 'docx', 'txt', 'rtf', 'odt', 'html', 'xml', 'csv', 'json', 'xls', 'xlsx', 'ppt', 'pptx', 'odp', 'epub'];
        const openLink = (link) => {
            if(!window.cordova) {
                window.open(link, '_blank').focus();
            } else {
                cordova.InAppBrowser.open(link, "_system");
            }
        }
        if(prop.filesArray?.length == 1) {
            const ext = prop.filesArray[0].file?.split('.')?.pop().toLowerCase();
            const isImage = commonImageExtensions.includes(ext)
            const isDoc = documentFormats.includes(ext)
            return (
            <Box>
                {isImage && 
                <Fancybox options={{ Carousel: { infinite: false } }}>
                    <img data-fancybox={gallery_name} href={ cdn + "chat/" + prop.filesArray[0]?.file } src={ cdn + "chat/" + prop.filesArray[0]?.file } style={{ width: 200, height: 'auto', borderRadius: 3, verticalAlign: 'middle' }} />
                </Fancybox>}
                {isDoc &&
                    <Box sx={{ width: '150px', height: "150px", display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'column', gap: 2, p: 2, border: 3, borderColor: 'divider', borderRadius: "10px" }} >
                        <Box sx={{ borderRadius: 2, background: "#fff", height: "100px", width: "100px", overflow: "hidden", position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center', }} 
                            href={ cdn + "chat/" + prop.filesArray[0]?.file}
                            onClick={() => openLink(cdn + "chat/" + prop.filesArray[0]?.file)}
                            >
                                <Box >
                                <Typography sx={{textAlign: "center"}}>{ext.toUpperCase()}</Typography> 
                                <Typography sx={{fontSize:"75%"}}>{"Click here to open"}</Typography>
                                </Box>
                                
                            </Box>                        
                    </Box>
                }
            </Box>
            )
        } else if(prop.filesArray?.length > 1) {
            return (
            <Fancybox options={{ Carousel: { infinite: false } }}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: 200, cursor: 'pointer' }}>
                    <img src={ cdn + "chat/" + prop.filesArray[0].file } style={{ width: 200, height: 'auto', borderRadius: 3, verticalAlign: 'middle' }} />
                    <Box data-fancybox={gallery_name} href={ cdn + "chat/" + prop.filesArray[0].file } sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}>
                            <Chip label={ "+" + (Object.keys(prop.filesArray).length - 1) + " images" } sx={{ zIndex: 2, background: "#fff" }} />
                    </Box>
                    {prop.filesArray?.map((fileObj, index) => {
                        if(index != 0){
                            return <img key={index} data-fancybox={gallery_name} href={ cdn + "chat/" + fileObj?.file } src={ cdn + "chat/" + fileObj?.file } style={{ display: 'none' }} />                            
                        }
                    })}
                </Box>
            </Fancybox>)
        }
    }

    const Message = (prop) => {
        var data = JSON.parse(prop.data.data);
        return (
            <Box sx={{ width: '100%', px: 3, py: 3 }}>
                <Stack sx={{ width: '100%'}} direction={ prop.data.created_by == customer ? 'row-reverse' : 'row'} justifyContent={ prop.data.created_by == customer ? 'flex-end' : 'flex-start'} spacing={2} alignItems="start">
                    <Box sx={{ mt: 1 }}>
                        { prop.data.creator_details ?
                            <Avatar {...stringAvatar(prop.data.creator_details.name)} />
                            :
                            null
                        }
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <Box>                            
                            <Paper sx={{ p: 2, mb:1, background: (data.type == "audio" ? '#fff' : ''), color: (data.type == "audio" ? '#000' : '') }}>
                                { prop?.data?.creator_details ?
                                    <Typography variant="caption" component="div" sx={{ mb:1 }}>{prop.data?.details?.name} | {prop.data?.creator_details?.name}</Typography>
                                    :
                                    <></>
                                }
                                { data.type == "text" &&
                                    <Typography variant="body" component="p">{data.message}</Typography>
                                }
                                
                                { data.type == "form" &&
                                    <>
                                    {
                                    portal == "supplier" ?
                                        <>
                                            <Typography variant="body" component="p" sx={{ mb:1 }}>New Order Request Sent</Typography>
                                            <Typography variant="caption" component="div"><Chip sx={{px:2, borderRadius: "10px"}} onClick={() => {navigate(`/supplier/forms/manage/${data?.form_id}`)}} label={<Typography variant='caption'>{data?.form_name}</Typography>} variant="default" /></Typography>
                                        </> :
                                        <>
                                            <Typography variant="body" component="p" sx={{ mb:1 }}>New Order Request</Typography>
                                            <Typography variant="caption" component="div" sx={{ mb:1 }}>{ data?.form_name }</Typography>
                                            <NewOrder form_id={ data?.form_id } message_id={ data?.id } />
                                        </>
                                    }
                                    </>
                                }

                                { data.type == "form_order" &&
                                    <>
                                        <Typography variant="body" component="p" sx={{ mb:1 }}>New Form Order</Typography>
                                        <Typography variant="caption" component="div" sx={{ mb:1 }}>{ data.form_name }</Typography>
                                        <Button variant="contained" onClick={() => { navigate("/order/" + data.voucher_id) }}>View Order</Button>
                                    </>
                                }

                                { data.type == "catalog_order" &&
                                    <>
                                        <Typography variant="body" component="p" sx={{ mb:1 }}>New Catalog Order</Typography>
                                        <Button variant="contained" onClick={()=> { navigate("/order/" + data.voucher_id) }}>View Catalog Order</Button>
                                    </>
                                }

                                { data.type == "customorder" && 
                                    <>
                                        <Typography variant="body" component="p" sx={{ mb:1 }}>New Custom Order</Typography>
                                        <Button variant="contained" onClick={() => { navigate("/order/" + data.id) }}>View Order</Button>
                                    </>
                                }

                                { data.type == "attachments" &&   
                                    <Images filesArray={data.files} />
                                }

                                { data.type == "audio" &&
                                    <>
                                    {/* <ReactAudioPlayer src={ cdn + "audio/" + data.file } controls /> */}
                                    <Button variant="outlined" startIcon={<PlayCircleIcon />} onClick={() =>{ setAudioSrc(`${cdn}audio/${data.file}`); setShowAudioModal(true); }}>
                                        Play
                                    </Button>
                                    </>
                                }
                            </Paper>
                            <Typography variant="caption" component="div">{formatDistance(new Date(prop.data.created_at), new Date(), { addSuffix: true })}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        )
    }

    const SendOrderForm = (prop) => {
        
        const [loading, setLoading] = useState(false);
        const [open, setOpen] = useState(false);
        const [form, setForm] = useState(null);
        const [forms, setForms] = useState([]);

        const handleOpen = () => {
            setOpen(true);
            prop.toggleDrawer(false);
        }

        const handleClose = () => {
            setOpen(false);
            setForm([]);
        }

        const handleSubmit = (event) => {
            event.preventDefault();
            if(!!form && !!form?.id){
                setLoading(true);
                axios.post( url + 'api/chat/message', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    creator: portal == "supplier" ? 2 : 3,
                    channel: channel?.name,
                    buyer: channel?.buyer,
                    seller: channel?.seller,
                    data: JSON.stringify({ type: "form", form_id: form?.id, form_name: form?.name }),
                }, { headers: { Authorization: `Bearer ${token}` }})
                    .then(function (response) {
                        handleClose();
                        // setMessages('');
                        // setMessages((prev) => [...prev, ...response.data.messages]);
                    })
                    .catch(function (error) {
                        console.log({error});
                    })
                    .finally(() => {
                        setLoading(false);
                    });

            }else{
                alert("Please select an order form before proceeding!")
            }
        }

        const fetchForms = (query) => {
            if (!!query?.length) {
                axios.post( url + 'api/suppliers/chat/forms/search', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    query: query
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    setForms(response?.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }

        return (
            <>
                <ListItemText primary="Order Form"  onClick={handleOpen} />
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>Order Form</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                        <form onSubmit={handleSubmit}>
                            <InputLabel shrink>Name</InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                options={ forms }
                                size={"small"}
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                // dataSource={forms}
                                onInputChange={(event, newInputValue) => { fetchForms(newInputValue) }}
                                value={form}
                                onChange={(event, newValue) => {
                                    setForm(newValue)
                                }}
                                isOptionEqualToValue={(option, value) => {
                                    if(!value){
                                        return true
                                    }
                                    return option?.id == value?.id;
                                }}
                                renderInput={(params) => <TextField placeholder={"Search order form by name..."} {...params} required={true} />
                            }
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                <LoadingButton type="submit" variant="contained" loading={loading} loadingIndicator="Loading…" >Send</LoadingButton >
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    const ImageUploader = (prop) => {
        const {uploadedItem} = prop
        const inputFile = useRef(null) 
        const [open, setOpen] = useState(false)
        const handleOpen = () => {
            prop.toggleDrawer(false)
            inputFile.current.click()
        }

        const clearInputValue = () => {
            inputFile.current = null;
          };

        const handleSubmit = async (event) => {
            event.preventDefault();
            const files = inputFile.current.files;
            console.log({files})
            const formData = new FormData();
            formData.append("customer", customer);
            formData.append("contact", contact);
            formData.append("phone", phone);
            formData.append("creator", portal == "supplier" ? 2 : 3);
            formData.append("channel", channel.name);
            formData.append("buyer", channel.buyer);
            formData.append("seller", channel.seller);
            if(uploadedItem === "images"){
                for (let i = 0; i < files.length; i++) {
                if(files[i].type.includes("image")) {
                    formData.append('files[]', files[i]);
                }
            }}
            if(uploadedItem === "file"){
                for (let i = 0; i < files.length; i++) {
                    formData.append('files[]', files[i]);
            }}
            
            // console.log({hasFile: formData.has('files[]')})

            try {
                // Send a POST request to the Laravel endpoint with the FormData object
                if(formData.has('files[]')){
                const response = await axios.post( url + 'api/chat/message', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                });}

                clearInputValue();
                
            } catch (error) {
                console.error({error});
            }
        }

        return (
            <>
                <ListItemText primary={uploadedItem == "images" ? "Image" : "File"}  onClick={handleOpen} />
                { uploadedItem === "images" && <input type="file" accept="image/*" id="files" ref={inputFile} style={{display: 'none'}} multiple onChange={handleSubmit} />}
                { uploadedItem === "file" && <input type="file" accept="application/pdf" id="files" ref={inputFile} style={{display: 'none'}} onChange={handleSubmit} />}
            </>
        );
    }

    const ChatBar = () => {
        const [open, setOpen] = React.useState(false);
        const [message, setMessage] = React.useState("");
        const textFieldRef = useRef(null);

        const toggleDrawer = (newOpen) => () => {
            setOpen(newOpen);
        }; 
     
        const sendMessage = (event) => {
            event.preventDefault();
            if(message.trim().length){
            axios.post( url + 'api/chat/message', {
                customer: customer,
                contact: contact,
                phone: phone,
                creator: portal == "supplier" ? 2 : 3,
                channel: channel.name,
                buyer: channel.buyer || channel.customer_id,
                seller: channel.seller|| channel.customer_id,
                data: JSON.stringify({ type: "text", message: message }),
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setMessage('');
                // textFieldRef.current.focus()
                // setMessages((prev) => [...prev, ...response.data.messages]);
            })
            .catch(function (error) {
                console.log(error);
            })}
        };

        const sendAudio = (blob) => {
            console.log(blob);
            const timestamp = Date.now();
            const randomString = Math.random().toString(36).substring(2, 15);
            const filename = `${timestamp}_${randomString}.wav`;

            const formData = new FormData();
            formData.append('audio', blob, filename);
            formData.append('customer', customer);
            formData.append('contact', contact);
            formData.append('phone', phone);
            formData.append('creator', portal == "supplier" ? 2 : 3);
            formData.append('channel', channel.name);
            formData.append('buyer', channel.buyer);
            formData.append('seller', channel.seller);
            formData.append('filename', filename);
            formData.append('data', JSON.stringify({ type: "audio", file: filename }));

            axios.post( url + 'api/chat/message', formData, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setMessage('');
                // setMessages((prev) => [...prev, ...response.data.messages]);
            })
            .catch(function (error) {
                console.log(error);
            });
        };

        const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);

        const handleTextFieldFocus = () => {
            setIsTextFieldFocused(true);
        };

        const handleTextFieldBlur = () => {
            setIsTextFieldFocused(false);
        };
 

        return (
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, px: 1, py: 2, zIndex: 99 }} elevation={3}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <>
                        <div onClick={toggleDrawer(true)} sx={{ p: 0, cursor: 'pointer'}}><IconPlus /></div>
                        <TextField id="outlined-basic" autoComplete="off" variant="outlined" sx={{ flexGrow: 1}} 
                                value={message}
                                inputRef={textFieldRef}
                                onFocus={handleTextFieldFocus}
                                onBlur={handleTextFieldBlur}
                                multiline
                                rows={1}
                                onChange={event => {
                                    const { value } = event.target;
                                    setMessage(value);
                                }}/>
                        <div onClick={(event) => {sendMessage(event); setMessage("")}} style={{padding: 0, cursor: 'pointer', marginRight: '10px', marginLeft: '10px'}}><IconSend /></div>
                    </>
                    { !isTextFieldFocused && message.trim() == '' && (isMobile || isTablet) && <AudioRecorder onRecordingComplete={(blob) => sendAudio(blob)} /> }
                </Box>
                <SwipeableDrawer anchor="bottom" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                    <List component="nav" aria-label="main mailbox folders" sx={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 10px)' }}>
                        <ListItemButton>
                            <ImageUploader uploadedItem={"images"} toggleDrawer={ toggleDrawer() } />
                        </ListItemButton>
                        <ListItemButton>
                            <ImageUploader uploadedItem={"file"} toggleDrawer={ toggleDrawer() } />                            
                        </ListItemButton>
                        { portal == "supplier" && 
                            <ListItemButton>
                                <SendOrderForm toggleDrawer={toggleDrawer} />
                            </ListItemButton>
                         }
                    </List>
                </SwipeableDrawer>
            </Paper>
        )
    }
   
    const scrollBottom = () => {
        setNewMessages(false);
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth"
        });
    };

    return (
        <>
        <Box id="scrollableDiv" ref={scrollRef} style={{paddingBottom: 50}}>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <>

            {showAudioModal && <Box className={"audioPlayer"} sx={{display: "flex", position: "fixed", top: "60 px",zIndex: 999, backgroundColor: "#fff", width: "100%"}} >
            <Box sx={{margin: "10px auto", width: "90%",  display: "flex",}} >
             
            <AudioPlayer
                autoPlay
                src={audioSrc}
                showSkipControls={false}
                showDownloadProgress={true}
                layout="horizontal"
                customVolumeControls={[]}
                customAdditionalControls={[]}
                showJumpControls={isMobile ? false : true}
                
            />
            <Box sx={{backgroundColor: "#fff", display: "flex"}}>
            <IconButton
            aria-label="close"
            onClick={() => {setShowAudioModal(false); setAudioSrc("")}}
            >
                <CloseIcon />
            </IconButton>
            </Box>
            </Box>
            </Box>}
                
                {FloatDetails ?
                    <FloatDetails />
                    :
                    <></>
                }
                
                <InfiniteScroll scrollThreshold={0.5} dataLength={messages.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} scrollableTarget="scrollableDiv" endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {messages?.map((row, index) => (
                        <Message key={row.id} data={row}></Message>
                    ))}
                </InfiniteScroll>
                
                {/* {  newMessages &&
                    <Fab variant="extended" sx={{ position: "fixed", bottom: (theme) => theme.spacing(12), left: "50%", transform: "translate(-50%, -50%)" }} onClick={scrollBottom}>New Messages</Fab>
                } */}
                <ChatBar />
                
                
                
            </>
        }
        </Box>

        {/* <Dialog open={showAudioModal} onClose={() => {setShowAudioModal(false); setAudioSrc("")}} fullWidth >
            <Box sx={isTablet ? {margin: "40px"} :{margin: "10px"}}>
            { isTablet && <IconButton
            aria-label="close"
            onClick={() => {setShowAudioModal(false); setAudioSrc("")}}
            sx={{
                position: 'absolute',
                right: 6,
                top: 6,
            }}
            >
                <CloseIcon />
            </IconButton>}
            <AudioPlayer
                autoPlay
                src={audioSrc}
                onPlay={e => console.log("onPlay")}
                showSkipControls={false}
                showDownloadProgress={true}
                layout="horizontal"
                customVolumeControls={[]}
                customAdditionalControls={[]}
                showJumpControls={isTablet ? true : false}
                
            />
            </Box>
        </Dialog> */}

        </>
    );
}

export default Chatter;
