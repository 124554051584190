import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { format, parse } from 'date-fns'
import TextField from '@mui/material/TextField';
import filter from 'lodash/filter';
import LoadingComponent from '../../components/LoadingComponent';
import { Chip } from '@mui/material';

function Ledgers() {
    const navigate = useNavigate();
    const { url, contact, customer, token, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [searchData, setSearchData] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Ledgers List");
        }
    }, [ ]);

    const [fetchedCustomers, setfetchedCustomers] =  useState([]);

    function fetchCustomers(){
        axios.post( url + 'api/ledger/list', { 
            customer: customer,
            portal: portal, 
            contact: contact
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response56", response);
            console.log("Customer details fetched successfully")
            setfetchedCustomers(response?.data);
            if(portal == "supplier") {
                setSearchData(response?.data?.customers);
                setLoading(false)
            } else if(portal === "buyer") {
                setSearchData(response?.data?.suppliers);
                setLoading(false)
            }            
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    useEffect(() => { fetchCustomers() }, []);

    useEffect(() => {
        if(query.length) {
            if(portal == "supplier") {
                var filtered = filter(fetchedCustomers?.customers, function(o) {
                    return o.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            } else {
                var filtered = filter(fetchedCustomers?.suppliers, function(o) {
                    return o.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            }
            setSearchData(filtered);
        } else {
            if(portal == "supplier") {
                setSearchData(fetchedCustomers.customers);
            } else {
                setSearchData(fetchedCustomers.suppliers);
            }
        }
    }, [ query ]);

    const Item = (prop) => {
        if(prop.data.buyer != "1" && prop.data.supplier != "1") {
            return (
                <Paper onClick={() => { navigate("/ledger/" + (portal == "supplier" ? prop.data.buyer : prop.data.supplier) ) }} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                    <Box sx={{ px: 2, py:2, }}>
                        <Typography variant="subtitle1" component="h6">{prop.data.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ prop?.data?.city_name }</span></Typography>
                        {
                            portal === "buyer" && !!prop.data?.seller_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={prop.data?.seller_msme} /></Box>
                        }
                        {
                            portal === "supplier" && !!prop.data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={prop.data?.buyer_msme} /></Box>
                        }
                    </Box>
                    <Grid container>
                        <Grid item xs={6} sx={{ borderTop: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Last visited on</Typography>
                            <Typography variant="body" component="p">{prop.data.last_visit ? format(parse(prop.data.last_visit, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Last invoiced on</Typography>
                            <Typography variant="body" component="p">{prop.data.last_invoice ? format(parse(prop.data.last_invoice, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )
        }
    }


    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title={ portal == "supplier" ? "Buyers" : "Suppliers" } backToHome="true">
                    <TextField sx={{ mb: 2 }} fullWidth
                            placeholder={ portal === "buyer" ? "Search and filter Suppliers" : "Search and filter Buyers"}
                            value={query}
                            onChange={event => {
                                const { value } = event.target;
                                setQuery(value);
                            }} />
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {searchData?.length > 0 && searchData?.map((row, index) => (
                            <Item key={row?.id} data={row}></Item>
                        ))}
                        {searchData?.length == 0 && <Typography variant="subtitle1" component="h6" sx={{textAlign: "center", my: "10px"}}>No Data</Typography>}
                    </Box>
                                                     
            </Layout>
        }
        </>
    );
}

export default Ledgers;
