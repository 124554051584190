import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Card from '@mui/material/Card';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadButton from '../../components/VoucherUploader';
import LoadingComponent from '../../components/LoadingComponent';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, Drawer, Fab, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import NoData from '../../components/NoData';

function Catalog() {
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);
    const [level, setLevel] = useState(4);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(0);

    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Supplier Catalog");
        }
    }, [ ]);

    useEffect(() => {
        let tempCategories = [];
        items.forEach(item => {
            let i=1;
            let category = item.category_details;    
            while(i<level){
                category = category?.parent;
                i++;
            }
            tempCategories.push(category);
        });
        tempCategories = tempCategories.filter((item) => item?.parent_id == category);
        setCategories([
            ...new Map(tempCategories.map((item) => [item?.id, item])).values(),
        ]);
    }, [items, level, category]);

    const fetchData = () => {
        var page = currentPage + 1;
        axios.post( url + 'api/suppliers/catalog/categories?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems((prev) => [...prev, ...response.data.data]);
            setCurrentPage(parseInt(response.data.current_page));
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Item = (prop) => {
        const [data, setData] = useState(prop.data);
                
        useEffect(() => {
            setData(prop.data);
        }, []);

        return (
            <Card onClick={() => {
                if (level == 1) {
                    navigate("/supplier/catalog/manage/" + data.id + "/1") 
                } else {
                    setLevel(level - 1);
                    setCategory(data.id);
                }
            }} 
            sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 2, borderRadius: 2}} elevation={2}>
                <Box sx={{ px: 2, py:3,}}>
                    <Typography variant="subtitle2" component="p">{data.name}</Typography>
                </Box>
            </Card>
        )
    }
    

    // const [drawerOpen, setDrawerOpen] = useState(false);

    // const handleDrawerOpen = () => {
    //     setDrawerOpen(true)
    // }

    // const handleDrawerClose = () => {
    //     setDrawerOpen(false)
    // }

    // const NewCategory = () => {

    //     const [categories, setCategories] = useState(0);
    //     const [type, setType] = useState(0);

    //     const handleTypeChange = (event) => {
    //         setType(event?.target?.value)
    //     }

    //     const top100Films = [
    //         { label: 'The Shawshank Redemption', year: 1994 },
    //         { label: 'The Godfather', year: 1972 },
    //         { label: 'The Godfather: Part II', year: 1974 },
    //         { label: 'The Dark Knight', year: 2008 }, ]


    //     return(
    //         <>
    //             <Fab color="primary" aria-label="add" size={"medium"} onClick={handleDrawerOpen} sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
    //                 <IconPlus />
    //             </Fab>
    //             <Dialog open={drawerOpen} onClose={handleDrawerClose}>
    //                 <DialogTitle>
    //                     <Typography variant="body2" component="p" sx={{fontWight: 700}}>Add New Category</Typography>
    //                 </DialogTitle>
    //                 <DialogContent sx={{ width: '80vw', maxWidth: '400px' }}>   
    //                     <InputLabel shrink>Select Categories</InputLabel>
    //                     <Autocomplete
    //                         sx={{mb:2}}
    //                         options={top100Films}
    //                         renderInput={(params) => <TextField {...params}/>}
    //                     />

    //                     <InputLabel shrink>Type</InputLabel>
    //                     <FormControl fullWidth sx={{mb:3}}>
    //                         <Select
    //                         value={type}
    //                         onChange={handleTypeChange}
    //                         displayEmpty
    //                         inputProps={{ 'aria-label': 'Without label' }}
    //                         >
    //                         <MenuItem value={0}></MenuItem>
    //                         <MenuItem value={1}>External</MenuItem>
    //                         <MenuItem value={20}>Internal</MenuItem>
    //                         </Select>
    //                     </FormControl>
    //                     <Box sx={{display: "flex", justifyContent: "center"}}>
    //                         <Button fullWidth variant="contained">Submit</Button> 
    //                     </Box> 
    //                 </DialogContent>
    //             </Dialog>
    //         </>
    //     )
    // }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <>
            <Layout title="Catalog" backToHome="true">
                <Box>
                    {items?.length > 0 ?
                        <>
                            <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                                <Typography variant="body2" component="p" sx={{p:1}}>All Categories</Typography>
                                {categories?.map((row, index) => (
                                    <Item key={row.id} data={row}></Item>
                                ))}
                            </InfiniteScroll> 
                        </>  
                        :
                        <NoData />
                    }                 
                </Box> 
                {/* <NewCategory /> */}
            </Layout>            
            </>
        }
        </div>
    );
}

export default Catalog;
