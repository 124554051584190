import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Card from '@mui/material/Card';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import LoadingComponent from '../../components/LoadingComponent';
import { Alert } from '@mui/material';
import Person2TwoToneIcon from '@mui/icons-material/Person2TwoTone';

function BuyerCatalogCategories() {
    let { supplier } = useParams();
    const navigate = useNavigate();
    const { url, phone, contact, customer, token, } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [supplierDetails, setSupplierDetails] = useState(null);
    const [level, setLevel] = useState(4);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(0);

    useEffect(() => {
        setItems([]);
        fetchData();
    }, []);

    useEffect(() => {
        let tempCategories = [];
        items.forEach(item => {
            let i=1;
            let category = item.category_details;    
            while(i<level){
                category = category?.parent;
                i++;
            }
            tempCategories.push(category);
        });
        tempCategories = tempCategories.filter((item) => item?.parent_id == category);
        setCategories([
            ...new Map(tempCategories.map((item) => [item?.id, item])).values(),
        ]);
    }, [items, level, category]);

    const fetchData = () => {
        axios.post( url + 'api/buyers/catalog/suppliers/categories', {
            supplier: supplier,
            customer: customer,
            contact: contact,
            phone: phone,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems(response.data.data);
            setSupplierDetails(response?.data?.supplier)
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Item = (prop) => {
        const [data, setData] = useState(prop.data);
        
        useEffect(() => {
            setData(prop.data);
        }, []);

        return (
            <Card onClick={()=> { 
                if (level == 1) {
                    navigate("/buyer/catalog/products/" + data.id + "/" + supplier);
                } else {
                    setLevel(level - 1);
                    setCategory(data.id);
                }
            }}
            sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 2, borderRadius: 2}} elevation={2}>
                <Box sx={{ px: 2, py:3,}}>
                    <Typography variant="subtitle2" component="p">{data.name}</Typography>
                </Box>
            </Card>
        )
    }

    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Catalog" backToTab={true} link={"/buyer/catalog/1"} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Alert icon={<Person2TwoToneIcon/>} sx={{p:1, mb:1}} severity="info">
                        <Typography variant="overline" sx={{fontSize: ".7rem", fontWeight: 600}}> Supplier: {supplierDetails?.name}, <span style={{ fontWeight: 500, fontSize: "0.9em", color: "gray"}}>{supplierDetails?.city_details?.name}</span></Typography>
                    </Alert>
                    <Typography variant="overline" sx={{mb:2, p:1}}>Categories</Typography>
                    <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                        {categories.map((item) => (
                            <Item key={item?.id} data={item}></Item>
                        ))}
                    </InfiniteScroll>
                </Box>
            </Layout>
        }
        </>
    );
}

export default BuyerCatalogCategories;
