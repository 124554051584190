import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; 
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useParams } from "react-router-dom";
import { IconShoppingCart, IconPlus, IconMinus } from '@tabler/icons-react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Fancybox from '../../components/Fancybox';
import LoadingComponent from '../../components/LoadingComponent';
import NoData from '../../components/NoData';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function BuyerCatalogProducts() {
    let { category, supplier } = useParams();
    
    const { cdn, url, phone, contact, customer, token, } = useContext(GlobalContext);
    const title = "Catalog"
    
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [count, setCount] = useState(0);

    const [categoryDetail, setCategoryDetail] = useState(null);

    useEffect(() => {
        const fetchCategoryDetails = () => {
            axios.post( url + 'api/suppliers/category/details', {
                customer: customer,
                category: category,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setCategoryDetail(response?.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        fetchCategoryDetails()
    }, [])

    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
    }, []);
 
    const fetchData = () => {
        let page = currentPage + 1;
        let params = {
            customer: customer,
            contact: contact,
            phone: phone,
            category: category,
        }
        if(supplier != 0) {
            params = {
                customer: customer,
                contact: contact,
                phone: phone,
                category: category,
                supplier: supplier,
            }
        }
        axios.post( url + 'api/buyers/catalog/products?page=' + page, params, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log({response})
            setItems((prev) => [...prev, ...response.data.products.data]);
            setSizes(response.data.sizes);
            setCount(response.data.count);
            setCurrentPage(parseInt(response.data.products.current_page));
            if(response.data.products.current_page == response.data.products.last_page) {
                setHasMore(false);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    function Item (prop) {
        
        const [open, setOpen] = useState(false);
        
        const handleOpen = () => { setOpen(true) }
    
        const handleClose = () => { setOpen(false) }

        const AddToCartSizes = (prop) => {     

            let ts = [];
            prop.data?.sizes?.name.split(",").forEach(function(item) {
                ts.push({ name: item, qty: 0 });
            })
            
            const [product_sizes, setProductSizes] = useState(ts)

            // console.log({product_sizes})

            const updateSizes = (value, index) => {
                // console.log(index);
                const newArray = product_sizes.map((item, i) => {
                if (index === i) {
                    return { name: item.name, qty: parseFloat(value) };
                } else {
                    return item;
                }
                });
                setProductSizes(newArray);
            }

            const handleSubmit = (event) => {
                event.preventDefault();
                let check = 0;
                product_sizes.map((item, i) => {
                    if(item.qty > 0) {
                        check++;
                    }
                });
                if(check == 0) {
                    alert("Add quantity for one or more sizes");
                } else {
                    setLoading(true);
                    handleAddToCart();
                }
            }

            const handleAddToCart = () => {
                axios.post( url + 'api/buyers/catalog/cart/add', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    product: prop.data.id,
                    sizes: product_sizes,
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    alert(response.data.message);
                    setCount(response.data.count);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                });
            }

            return (
                <>
                    <Button size="small" onClick={handleOpen} startIcon={<IconShoppingCart size={20} />} sx={{fontSize: "0.7em"}} variant="text" >Add To Cart</Button>
                    <Dialog onClose={handleClose} open={open}>
                        <DialogTitle>Enter Sizes Quantity</DialogTitle>
                        <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                            <Alert severity="info" sx={{ mb: 2}}>Price may vary for different sizes</Alert>
                            <form onSubmit={handleSubmit}>
                                {product_sizes.map((item, index) => (
                                    <Box key={index}>
                                        <InputLabel shrink>{item?.name?.toUpperCase()}</InputLabel>
                                        <TextField size="small" sx={{ mb: 2 }} autoComplete='off' 
                                            fullWidth inputProps={{ type: 'number'}}
                                            onChange={(event) => { updateSizes(event?.target?.value, index) }} 
                                        />
                                    </Box>
                                ))}
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                    <Button type="submit" variant="contained">Add to Cart</Button>
                                </Box>
                            </form>
                        </DialogContent>
                    </Dialog>
                </>
            );
        }

        const AddToCartQty = (prop) => {
            
            const [product_qty, setProductQty] = useState("")

            const handleSubmit = (event) => {
                event.preventDefault();
                setLoading(true);
                handleAddToCart();
            }

            const handleAddToCart = () => {
                axios.post( url + 'api/buyers/catalog/cart/add', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    product: prop.data.id,
                    qty: product_qty,
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    alert(response.data.message);
                    setCount(response.data.count);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
            }

            return(
                <>
                    <Button size="small" startIcon={<IconShoppingCart size={20} />} onClick={handleOpen} variant="text">Add to Cart</Button>
                    <Dialog onClose={handleClose} open={open}>
                        <DialogTitle>Enter Quantity</DialogTitle>
                        <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                            <form onSubmit={handleSubmit}>
                                <InputLabel shrink>Quantity</InputLabel>
                                <TextField sx={{ mb: 2 }} autoComplete='off' fullWidth required={true} inputProps={{ type: 'number'}}
                                    value={product_qty}
                                    onChange={event => {
                                        const { value } = event.target;
                                        setProductQty(value);
                                    }} />
                                   
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                    <Button type="submit" variant="contained">Add to Cart</Button>
                                </Box>
                            </form>
                        </DialogContent>
                    </Dialog>
                </>
            );
        }

        const timestamp = Date.now();
        const randomString = Math.random().toString(36).substring(2, 15);
        const gallery_name = `${timestamp}_${randomString}`;
        var images = JSON.parse(prop?.data?.image);

        return (
            <Card sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center',}}>
                
                {(images != null && images?.length > 0) ? 
                        <Fancybox options={{ Carousel: { infinite: false } }}>
                            {/* <CardMedia sx={{ height: 180 }} image={cdn + 'catalog/' + images[0]?.file} data-fancybox={gallery_name} href={cdn + 'catalog/' + images[0].file}/> */}
                            <CardMedia component="div" sx={{ position: 'relative', overflow: 'hidden', width: '100%', paddingTop: '75%' }}>
                                <img
                                src={`${cdn}catalog/${images[0]?.file}`}
                                alt="Product Image"
                                data-fancybox={gallery_name}
                                href={`${cdn}catalog/${images[0].file}`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                />
                            </CardMedia>
                        </Fancybox>
                        : 
                        <Box sx={{height: 180, background: "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography variant="caption">No Image</Typography>
                        </Box>
                }
                <CardContent sx={{ borderBottom: 1, borderColor: 'divider', py:1, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Typography variant="body2" color="text.secondary" component="div">{prop.data.name}</Typography>
                    <Typography variant="overline" sx={{fontSize: ".7rem", mb:2}}> Supplier: {prop.data.supplier_name}, <span style={{ fontSize: "0.8em", color: "gray"}}>{prop.data.city_name}</span></Typography>
                    {prop.data.sizes && 
                        <Typography variant="overline"> Size: {prop?.data?.sizes?.name}</Typography>
                    }
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    {prop?.data?.sizes ?
                        <AddToCartSizes data={prop.data}></AddToCartSizes>
                        :
                        <AddToCartQty data={prop.data}></AddToCartQty>
                    }
                </CardActions>
            </Card>
        )
    }

    function Cart(prop) {
        const [open, setOpen] = useState(false)
        const [remarks, setRemarks] = useState("")
        const [cart_items, setCartItems] = useState([])
        
        const handleOpen = () => {
            // setLoading(true);
            axios.post( url + 'api/buyers/catalog/cart', {
                customer: customer,
                contact: contact,
                phone: phone,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                console.log("cart", response.data.data);
                setCartItems(response.data.data);
                setLoading(false);
                setOpen(true);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    
        const handleClose = () => {
            setOpen(false);
        }

        const CartQty = (prop) => {

            const [qty, setQty] = useState(parseFloat(prop.qty));

            const updateQty = (mode) => {
                if(mode == "+") {
                    setQty(qty + 1)
                } else if(mode == "-") {
                    if(qty == 1) { setQty(1) }
                    else { setQty(qty - 1) }
                }
                axios.post( url + 'api/buyers/catalog/cart/update', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    id: prop.id,
                    qty: qty == 1 ? 1 : qty + 1,
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 2 , mt: 1}}>
                    <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} 
                    variant="outlined" size="small" onClick={event => {  updateQty("-"); }} ><IconMinus  /></Button>
                    <TextField id="outlined-basic" label="Quantity" variant="outlined" size="small" value={qty} />
                    <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} 
                    variant="outlined" size="small" onClick={event => { updateQty("+"); }}><IconPlus  /></Button>
                </Box>
            )

        }

        const CartItems = (prop) => {
            
            const gallery_name = `${Date.now()}`;

            const handleDelete = (row) => {
                axios.post(url + 'api/buyers/catalog/cart/delete', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    product: row.id,
                }, { headers: { Authorization: `Bearer ${token}` } })
                    .then(function (response) {
                        alert(response.data.message);
                        setCount(response.data.count);
                        setLoading(false); 
                    })
                    .catch(function (error) {
                        console.log(error);
                        setLoading(false); 
                    });
            };

            return (
                <>
                    {prop.data.map((row, index) => {
                        console.log({rowItem: row})                        
                            return(
                            <Paper sx={{ display: 'flex', mb: 3 }} key={index} elevation={2}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    {/* <CardMedia sx={{ height: 200 , width: "100%", borderRadius: 1, objectFit: 'contain', borderRadius: '4px'  }} image={cdn + 'catalog/' + JSON.parse(row.image)[0]?.file}/> */}
                                    
                                    {!!JSON.parse(row.image) && JSON.parse(row.image)?.length > 0 ?
                                        <CardMedia component="div" sx={{ position: 'relative', overflow: 'hidden', width: '100%', paddingTop: '75%' }} >
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    zIndex: 1,
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                                    },
                                                }}
                                                onClick={() => handleDelete(row)}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                            <img
                                                src={cdn + 'catalog/' + JSON.parse(row.image)[0]?.file}
                                                // href={cdn + 'catalog/' + JSON.parse(row.image)[0]?.file}
                                                alt="Product Image"
                                                data-fancybox={gallery_name}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </CardMedia>
                                        :
                                        <Box sx={{height: 280, position: 'relative', overflow: 'hidden', background: "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    zIndex: 1,
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                                    },
                                                }}
                                                onClick={() => handleDelete(row)}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                            <Typography variant="caption">No Image</Typography>
                                        </Box>
                                    }

                                    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', p: 2 }}>
                                        <Typography variant="overline" sx={{fontSize: "0.8rem", mb:1}} >{row.name.toUpperCase()}</Typography>
                                        {
                                            row?.supplier_name && 
                                            <Typography variant="overline" sx={{fontSize: ".7rem", mb:2}}> Supplier: {row?.supplier_name}, <span style={{ fontSize: "0.8em", color: "gray"}}>{row?.city_name}</span></Typography>
                                        }
                                        {
                                            row?.size &&
                                            <Typography variant="overline" sx={{mb:2}}>Size: {row.size.toUpperCase()}</Typography>
                                        }
                                        <CartQty id={row.id} qty={row.qty} />
                                    </Box>
                                </Box>
                            </Paper>)
                    })}
                </>
            )
        }

        const placeOrder = () => {
            axios.post( url + 'api/buyers/catalog/order', {
                customer: customer,
                contact: contact,
                phone: phone,
                notes: remarks,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    setCartItems([]);
                    handleClose();
                    prop.setCount(0);
                    alert("Order placed successfully");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        return(
            <>
                <Fab variant="extended" color="primary" aria-label="add" size={"medium"} onClick={handleOpen} sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
                    <IconShoppingCart />
                    <Box sx={{ ml: 1 }}>{prop.count}</Box>
                </Fab>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>Cart</DialogTitle>
                    <DialogContent sx={{ maxWidth: '300px' }}>
                        {
                            Object.keys(cart_items).length ?
                            <Box>
                                <CartItems data={cart_items} />
                            </Box>
                            :
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 3, p: 2 }}>No items in cart</Box>
                        }

                        {
                                Object.keys(cart_items).length ?
                                <Box sx={{ mt: 2 }}>
                                    <InputLabel shrink>Order Remarks</InputLabel>
                                    <TextField sx={{ mb: 2 }} fullWidth required={true} size="small"
                                    placeholder="Enter remarks if any"
                                        value={remarks}
                                        onChange={event => { setRemarks(event.target?.value) }} />
                                </Box>
                                : 
                                <></>
                        }

                        <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'row', gap: 3, mt: 1 }}>
                            <Button onClick={handleClose}  variant="outlined">Continue Shopping</Button>
                            {
                                Object.keys(cart_items).length ?
                                <Button type="submit" variant="contained" onClick={placeOrder}>Place Order</Button>
                                :
                                <></>
                            }
                        </Box>

                        
                    </DialogContent>
                </Dialog>
            </>)
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading}/>
            : 
            <Layout title={title}  backToTab={true} link={supplier != 0 ? `/buyer/catalog/categories/${supplier}` : "/buyer/catalog/0"} >
                    { !!categoryDetail?.name && 
                        <Alert sx={{p:1, mb:1}} icon={<CategoryTwoToneIcon />} severity="info">
                            <Typography variant="overline" sx={{fontSize: "0.7rem", fontWeight: 600}}>Category: {categoryDetail?.name}</Typography>
                        </Alert>
                    }                
                    {items?.length > 0 ?
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <>
                        <Box sx={{mb:2}}>
                            <Typography variant="overline" sx={{fontSize: "0.75rem", p:1}}>Products</Typography>
                        </Box>
                        <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '12px' }}>You have reached end of the list</p>}>
                            <Grid container spacing={2} alignItems="stretch">                                
                                {items?.map((item, index) => (
                                    <Grid item  xs={6} sm={4} md={3} lg={2} key={item?.id}>
                                        <Item data={item} index={index}></Item>
                                    </Grid>
                                ))}
                            </Grid> 
                        </InfiniteScroll>
                        <Cart setCount={setCount} count={count} />
                        </>
                    </Box>
                    :
                    <NoData />
                    }
                
            </Layout>
        }
        </div>
    );
}

export default BuyerCatalogProducts;
