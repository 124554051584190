import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Layout from '../../components/Layout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GlobalContext } from '../../contexts/GlobalContext';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useTheme } from '@emotion/react';
import NoData from '../../components/NoData';
import LoadingComponent from '../../components/LoadingComponent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete'
import { IconBuildingSkyscraper, IconHierarchy } from '@tabler/icons-react';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';


export default function SupportManagers() {
    //Test
    const { url, customer, token } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);

    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([])

    const [filterBy, setFilterBy] = useState("");
    
    const [selectedDesignation, setSelectedDesignation] = useState("")
    const [selectedDepartment, setSelectedDepartment] = useState("")

    const theme = useTheme();

    useEffect(()=>{
        if(filterBy === 1 && items.length !== 0){
            selectedDesignation ? setFilteredItems(items.filter((item, index) => item?.designation?.title === selectedDesignation)) 
            : 
            setFilteredItems([]);
        }else if(filterBy === 2 && items.length !== 0){
            selectedDepartment ? setFilteredItems(items.filter((item, index) => item?.department === selectedDepartment)) 
            : 
            setFilteredItems([]);
        }else {
            setFilteredItems([])
        }
    }, [selectedDepartment, selectedDesignation])

    // console.log({filteredItems})

    const StyledTableCell = styled(TableCell)(({ theme }) => ({        
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.secondary.main , // #044B7F
          borderRight: '1px solid #15649e !important',
          color: "#fff"
        },
        [`&.${tableCellClasses.body}`]: {
          borderRight: '1px solid #e3e1e1'
        },
        '&:last-child': {
            borderRight: 0
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#f2f2f2",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            
        },
    }));


    useEffect(() => {
        setItems([]);
        fetchData();
    }, []);

    const fetchData = () => {
        axios.post( url + 'api/support/managers' , {
            customer: customer,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems(response.data.managers);
            setLoading(false);            
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    const isMobile = useMediaQuery('(max-width:600px)');
    
    const DesktopTable = () => {
        return (            
            <Box style={{marginBottom: "5px", marginTop: "2px"}} > 
            <FilterComponent /> 
            <TableContainer component={Paper} sx={{marginTop: 2}}>
                <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="left">Name</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                        <StyledTableCell align="left">Phone</StyledTableCell>
                        <StyledTableCell align="left">Designation</StyledTableCell>
                        <StyledTableCell align="left">Department</StyledTableCell>
                    </StyledTableRow>                    
                </TableHead>
                <TableBody>
                {filteredItems.length == 0 ?
                    items?.map((item,index) => {
                        return <DesktopTableRow key={index+1} index={index+1} item={item} />
                    })
                    :
                    filteredItems?.map((item,index) => {
                        return <DesktopTableRow key={index+1} index={index+1} item={item} />
                    })
                }
                </TableBody>
                </Table>
            </TableContainer>
            </Box>           
        )}

    const DesktopTableRow = ({item, index}) => {

        const [style, setStyle] = useState({})
        const [numberStyle, setNumberStyle] = useState({})
        const openLink = (link) => {
            if(!window.cordova) {
                window.open(link, '_blank').focus();
            } else {
                cordova.InAppBrowser.open(link, "_system");
            }
        }
     
        return (
            <StyledTableRow>
              <StyledTableCell align="left">{index}</StyledTableCell>
              <StyledTableCell align="left">{item?.name}</StyledTableCell>
              <StyledTableCell align="left" sx={style} onClick={() => { openLink(`mailto:${item?.email}`) }}
               onMouseEnter={()=> setStyle({cursor: "pointer", color: theme.palette.primary.main, textDecoration: "underline"})} onMouseLeave={()=>setStyle({color: "#000"})}>{item?.email}</StyledTableCell>
              <StyledTableCell align="left" sx={numberStyle} onClick={() => { openLink(`tel:+${item?.cc}${item?.phone}`) }}
              onMouseEnter={()=> setNumberStyle({cursor: "pointer", color: theme.palette.primary.main, textDecoration: "underline"})} onMouseLeave={()=>setNumberStyle({color: "#000"})}>+{item?.cc} {item?.phone}</StyledTableCell>
              <StyledTableCell align="left">{item?.designation?.title || "Not available"}</StyledTableCell>
              <StyledTableCell align="left">{item?.department || "Not available"}</StyledTableCell>
            </StyledTableRow>
        );
    }

    const MobileView = () => {

        const Item = ({manager, index}) => {

            const openLink = (link) => {
                if(!window.cordova) {
                    window.open(link, '_blank').focus();
                } else {
                    cordova.InAppBrowser.open(link, "_system");
                }
            }
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', my:2 }}>
                    <Box sx={{padding: 2}}>
                        <Typography variant="h6" component="h6"  sx={{mb: 1, fontSize: "0.8em"}}><b>{manager?.name?.toUpperCase()}</b></Typography>
                        <Box sx={{display: 'flex', gap: 1, mb: 1, alignItems: "center"}}>
                            <EmailIcon fontSize='small'    />
                            <Typography variant="h6" component="h6" >{manager?.email}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', gap: 1, mb:1, alignItems: "center"}}>
                            <PhoneIcon fontSize='small'  />
                            <Typography variant="h6" component="h6" >+{manager?.cc} {manager?.phone}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', gap: 1, mb:1, alignItems: "center"}}>
                            <IconHierarchy fontSize='small'  />
                            <Typography variant="h6" component="h6">{manager?.designation?.title || "Not available"}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', gap: 1, mb:1, alignItems: "center"}}>
                            <IconBuildingSkyscraper fontSize='small'  />
                            <Typography variant="h6" component="h6">{manager?.department || "Not available"}</Typography>
                        </Box>
                        
                        
                        <Box sx={{display: "flex", gap: "5px", paddingBottom: "5px", paddingTop: "10px"}}>
                            <Button variant="contained" sx={{flex: 1}} onClick={() => { openLink(`mailto:${manager.email}`) }}>Email</Button>
                            <Button variant="contained" sx={{flex: 1}}  onClick={() => { openLink(`tel:+${manager.cc}${manager.phone}`) }}>Call</Button>
                        </Box>
                    </Box>
                </Paper>
            )
        };

        return(
        <> 
        <FilterComponent />       
        {filteredItems?.length === 0 ?
            items?.map((manager, index) => {
                return <Item manager={manager} index={index} key={index} />
            })
            :
            filteredItems?.map((manager, index) => {
                return <Item manager={manager} index={index} key={index} />
            })
        }
        </>
        )
    };

    function FilterComponent () {

        const uniqueDepartments = [...new Set(items?.reduce(( acc, item )=>{
            if(item?.department) {
                acc.push(item.department)
            }
            return acc
        }, [])
        )]
        
        const uniqueDesignations = [...new Set(items?.reduce(( acc, item )=>{
            if(item?.designation?.title) {
                acc.push(item?.designation?.title)
            }
            return acc
        }, [])
        )];

        const handleChange = (event) => {
            selectedDesignation && setSelectedDesignation("")
            selectedDepartment && setSelectedDepartment("")
            setFilterBy(event.target.value)
        };

        return (
            <Box sx={{display: "flex", width: "100%", gap:1, marginTop: 1, maxWidth: "780px" }}>
                <Box sx={{flex: 1}}>
                <FormControl sx={{ m: 1, width: "100%" }} size="small">
                    <InputLabel id="filter-by">Filter By</InputLabel>
                    <Select
                        labelId="filter-by"
                        id="demo-select-small"
                        value={filterBy}
                        label="Filter By"
                        onChange={handleChange}
                    >
                        <MenuItem value={""}>
                        <em>All Data</em>
                        </MenuItem>
                        <MenuItem value={1}>Designation</MenuItem>
                        <MenuItem value={2}>Department</MenuItem>
                    </Select>
                </FormControl>
                </Box>

                <Box sx={{display: "flex", flex: 2, width: "100%", justifyContent: "center", alignItems: "center", }}>
                <Autocomplete
                sx={{width: "100%", m: 1}}
                size="small"
                value={filterBy === 1 ? selectedDesignation : filterBy == 2 ? selectedDepartment : ""}
                onChange={(event, newValue) => {
                    if(filterBy === 1 ){
                        setSelectedDesignation(newValue)
                    }else if(filterBy === 2 ){
                        setSelectedDepartment(newValue)
                    }
                }}
                id="filter-component"
                options={filterBy === 1 ? uniqueDesignations :  filterBy === 2 ? uniqueDepartments : [] }
                renderInput={(params) => <TextField {...params} />}
                />
                </Box>

            </Box>
        )
    }


    const RsInfo = () => {

        function getDirection() {
            const url = "https://g.co/kgs/56ozMyc";
            window.open(url, '_blank');
        }
              
        return (
          <Card 
            sx={{
                display: 'flex',
                // flexDirection: isMobile ? 'column' : 'row',
                flexDirection: "column",
                alignItems: 'center',
                maxWidth: isMobile ? '500px' : '100%',
                margin: 'auto',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            }}
          >
            <CardMedia component="img" image={"rasiklalLogo.jpg"} alt="Rasiklal Sons Logo"
              sx={{
                width: isMobile ? '100%' : '300px',
                height: isMobile ? '100%' : '150px',
                objectFit: 'contain',
                padding: '16px',
              }}
            />

            <CardContent 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    padding: '16px',
                    // textAlign: isMobile ? 'center' : 'left',
                    textAlign: "center"
                }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, fontSize: "1rem" }}> RASIKLALSONS INTERMEDIARIES LLP</Typography>
              <Typography variant="body2">401, COSMOS PLATINUM BUSINESS PARK,</Typography>
              <Typography variant="body2">GOKHALE ROAD SOUTH, DADAR WEST,</Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>MUMBAI-400028</Typography>
              <Typography variant="body2">TEL: 022-24383851, 24383853</Typography>
              <Typography variant="body2">EMAIL: rasiklalsons@gmail.com</Typography>
              <Box sx={{mt: 2}}>
                <Button startIcon={<DirectionsOutlinedIcon />} variant="contained" onClick={getDirection}>Get Direction</Button>
              </Box>
            </CardContent>
          </Card>
        );
    };
      


    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Managers">
                <RsInfo />
                { (items.length === 0) 
                     ? <NoData />
                     : (isMobile ? <MobileView/> : <DesktopTable />) 
                 }
            </Layout>
        }
        
        </>
    );
}
