import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import NoData from '../../components/NoData';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MediaGrid from '../../components/MediaGrid';
import { useTheme } from "@emotion/react";
import LoadingComponent from '../../components/LoadingComponent';


function Profile() {
    const navigate = useNavigate();
    const { url, phone,  contact, customer, token, portal , cdn } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [contactDetails, setContactDetails] = useState([]);
    const [transportsDetails, setTransportsDetails] = useState([])    
    const [transportsDetails2, setTransportsDetails2] = useState([])
    const theme = useTheme();

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Profile");
        }
    }, [ ]);

    const openLink = (link) => {
        if(!window.cordova) {
            window.open(link, '_blank').focus();
        } else {
            cordova.InAppBrowser.open(link, "_system");
        }
    }

    const fetchData = () => {
        axios.post( url + 'api/profile', {
            customer: customer,
            contact: contact,
            phone: phone,
            portal: portal,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log({response})
            setData(response.data?.customer);
            setTransportsDetails(response.data?.transports)
            setTransportsDetails2(response.data?.transports2)
            setContactDetails(response.data?.contact);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    function stringAvatar(name) {
        var split = name.split(' ');
        return {
            sx: {
            bgcolor: stringToColor(name),
            },
            children: `${split[0][0]}${split[1] ? split[1][0] : ''}`,
        };
    }
    
    const ManageAccess = (props) => {
        // console.log("props.permissions", props.permissions);
        const [permissions, setPermissions] = useState(props.permissions);

        const [open, setOpen] = useState(false)

        // useEffect(() => {
        //     fetchData();
        // }, [ ]);
        
        const handleOpen = () => {
            setRoleChat(permissions.includes("chat") ? true : false);
            setRoleRasiklalChat(permissions.includes("rasiklal_chat") ? true : false);
            setRoleCheckins(permissions.includes("checkins") ? true : false);
            setRoleOrders(permissions.includes("orders") ? true : false);
            setRoleCustomOrders(permissions.includes("custom_orders") ? true : false);
            setRoleInvoices(permissions.includes("invoices") ? true : false);
            setRolePayments(permissions.includes("payments") ? true : false);
            setRoleCreditNotes(permissions.includes("creditnotes") ? true : false);
            setRoleDebitNotes(permissions.includes("debitnotes") ? true : false);
            setRoleTickets(permissions.includes("tickets") ? true : false);
            setRoleCatalog(permissions.includes("catalog") ? true : false);
            setRoleLedgers(permissions.includes("ledgers") ? true : false);
            setRoleProfile(permissions.includes("profile") ? true : false);
            setRolePlaceOrders(permissions.includes("place_orders") ? true : false);
            setOpen(true);
        };
        
        const handleClose = () => {
            setOpen(false);
        };    
        
        const [roleChat, setRoleChat] = useState(false);
        const [roleRasiklalChat, setRoleRasiklalChat] = useState(false);
        const [roleChechins, setRoleCheckins] = useState(false);
        const [roleOrders, setRoleOrders] = useState(false);
        const [roleCustomOrders, setRoleCustomOrders] = useState(false);
        const [roleInvoices, setRoleInvoices] = useState(false);
        const [rolePayments, setRolePayments] = useState(false);
        const [roleCreditNotes, setRoleCreditNotes] = useState(false);
        const [roleDebitNotes, setRoleDebitNotes] = useState(false);
        const [roleTickets, setRoleTickets] = useState(false);
        const [roleCatalog, setRoleCatalog] = useState(false);
        const [roleLedgers, setRoleLedgers] = useState(false);
        const [roleProfile, setRoleProfile] = useState(false);
        const [rolePlaceOrders, setRolePlaceOrders] = useState(false);

        const handleSubmit = () => {
            setLoading(true);
            var roles = [];
            if(roleChat) { roles.push("chat") }
            if(roleRasiklalChat) { roles.push("rasiklal_chat") }
            if(roleChechins) { roles.push("checkins") }
            if(roleOrders) { roles.push("orders") }
            if(roleCustomOrders) { roles.push("custom_orders") }
            if(roleInvoices) { roles.push("invoices") }
            if(rolePayments) { roles.push("payments") }
            if(roleCreditNotes) { roles.push("creditnotes") }
            if(roleDebitNotes) { roles.push("debitnotes") }
            if(roleTickets) { roles.push("tickets") }
            if(roleCatalog) { roles.push("catalog") }
            if(roleLedgers) { roles.push("ledgers") }
            if(roleProfile) { roles.push("profile") }
            if(rolePlaceOrders) { roles.push("place_orders") }
            axios.post( url + 'api/role', {
                roles: roles,
                contact: props?.data?.id,
                customer: customer,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                // var newPermissions = [];
                // response.data?.roles.map((row) => {
                //     newPermissions[row] = true;
                // });
                // console.log("response.data?.roles", response.data?.roles);
                // setPermissions(response.data?.roles);
                // console.log("permissions", permissions);
                fetchData();
                // navigate(0);
            })
            .catch(function (error) {
                console.log(error);
            });

        }

        return (
            <React.Fragment>
                <Button variant="contained" onClick={handleOpen}>Manage Access</Button>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>Manage Access</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }} control={<Switch checked={roleChat} onChange={(event) => { event.stopPropagation(); setRoleChat(event.target.checked)}} />} label="Chat" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleRasiklalChat} onChange={(event) => { event.stopPropagation(); setRoleRasiklalChat(event.target.checked)}} />} label="Rasiklal Chat" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleChechins} onChange={(event) => { event.stopPropagation(); setRoleCheckins(event.target.checked)}} />} label="Check-Ins" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleOrders} onChange={(event) => { event.stopPropagation(); setRoleOrders(event.target.checked)}} />} label="Orders" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleCustomOrders} onChange={(event) => { event.stopPropagation(); setRoleCustomOrders(event.target.checked)}} />} label="Custom Orders" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleInvoices} onChange={(event) => { event.stopPropagation(); setRoleInvoices(event.target.checked)}} />} label="Invoices" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={rolePayments} onChange={(event) => { event.stopPropagation(); setRolePayments(event.target.checked)}} />} label="Payments" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleCreditNotes} onChange={(event) => { event.stopPropagation(); setRoleCreditNotes(event.target.checked)}} />} label="Credit Notes" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleDebitNotes} onChange={(event) => { event.stopPropagation(); setRoleDebitNotes(event.target.checked)}} />} label="Debit Notes" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleTickets} onChange={(event) => { event.stopPropagation(); setRoleTickets(event.target.checked)}} />} label="Tickets" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleCatalog} onChange={(event) => { event.stopPropagation(); setRoleCatalog(event.target.checked)}} />} label="Catalog" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleLedgers} onChange={(event) => { event.stopPropagation(); setRoleLedgers(event.target.checked)}} />} label="Ledgers" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={roleProfile} onChange={(event) => { event.stopPropagation(); setRoleProfile(event.target.checked)}} />} label="Profile" />
                                </Grid>
                                <Grid item xs={6} sx={{ mb: 2 }}>
                                    <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }}  control={<Switch checked={rolePlaceOrders} onChange={(event) => { event.stopPropagation(); setRolePlaceOrders(event.target.checked)}} />} label="Place Orders" />
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                <Button type="submit" variant="contained">Save</Button>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }

    const Contact = (props) => {
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="h6" component="h6">{ props?.data?.name }</Typography>
                    <Typography variant="caption" component="p" sx={{ wordWrap: 'break-word' }}>{ props?.data?.designation_detail?.title }</Typography>
                </Box>
                <Grid container>
                    <Grid item xs={12} sx={{ borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Phone</Typography>
                        <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                        onClick={() => { if(props?.data?.cc && props?.data?.phone){openLink(`tel:+${props?.data?.cc}${props.data?.phone}`) }}}
                        >{props?.data?.phone ? props?.data?.phone : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderTop: 1,  borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Email</Typography>
                        <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                         onClick={() => {if(props?.data?.email){ openLink(`mailto:${props?.data?.email}`) }}}
                        >{props?.data?.email ? props?.data?.email : '-'}</Typography>
                    </Grid>
                    { contactDetails?.role == 1 &&
                    <Grid item xs={12} sx={{ borderTop: 1,  borderColor: 'divider', px: 1, py:1 }}>
                        <ManageAccess data={props?.data} permissions={ props?.data?.permissions?.map(a => a.name) } />
                    </Grid>
                    }
                </Grid>
            </Paper>         
        );
    }

    const deleteAccount = (event) => {
        event.preventDefault();
        var delete_url = "https://rasiklalsons.com/delete-account/";
        if(window.cordova) {
            cordova.InAppBrowser.open(delete_url, '_system', 'location=yes');
        } else {
            window.open(delete_url, "_blank")
        }
    }

    const Info = () => {
        const openWhatsapp = (number) => {
            if (!window.cordova) {
                window.open(`https://web.whatsapp.com/send?phone=${number}`, '_blank').focus();
            } else {
                window.open(`whatsapp://send?phone=${number}`, '_system');
            }
        }

        console.log("data",data.msme_certificate)
        const msmeCertificate = typeof data.msme_certificate === "string" ? JSON.parse(data.msme_certificate) : data.msme_certificate;
        return (
            <React.Fragment>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p" >{"Profile name"}</Typography>
                        <Typography variant="h6" component="h6">{data?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{data?.city_details?.name}</span></Typography>
                        {data?.msme && <Box><Chip variant="outlined" color="primary" size="small" label={data?.msme} /></Box>}
                    </Box>
                    {data?.alias !== null && <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p" >{"Alias name"}</Typography>
                        <Typography variant="h6" component="h6">{data?.alias || "Not available"} </Typography>
                    </Box>}
                    <Grid container>
                        <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Address</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>
                                {data?.address_1 ? data?.address_1 : ''}
                                {data?.address_2 ? ", "+ data?.address_2 : ''}
                                {data?.area ? ", "+ data?.area : ''}
                                {data?.city_details.name ? ", "+ data?.city_details.name : ''}
                                {data?.state_details.name ? ", "+ data?.state_details.name : ''}
                                {data?.country_details.name ? ", "+ data?.country_details.name : ''}
                                {data?.pincode ? " - "+ data?.pincode : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Phone</Typography>
                            <Typography variant="body" component="p" 
                            onClick={() => { if(data?.cc && data?.phone){openLink(`tel:+${data?.cc}${data?.phone}`);  }}}
                             >{data?.phone ? `+${data?.cc} ${data?.phone}` : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Email</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                            onClick={() => {if(data?.email){ openLink(`mailto:${data?.email}`) }}}
                            >{data?.email ? data?.email : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Whatsapp</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                            onClick={() => {if(data?.wa_number){ openWhatsapp(data?.wa_number) }}}
                            >{data?.wa_number ? data?.wa_number : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Gst treatment</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{data?.gst_treatment || 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">GSTIN</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{data?.gstin ? data?.gstin : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">PAN</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{data?.pan ? data?.pan : '-'}</Typography>
                        </Grid>
                        {!!data?.notes &&
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Notes</Typography>
                                <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{data?.notes || '-'}</Typography>
                            </Grid>
                        }
                       {/* { !!data?.reference &&
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Reference</Typography>
                                <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{data?.reference || '-'}</Typography>
                            </Grid>
                        } */}
                        {!!data?.remarks && 
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Remarks</Typography>
                                <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{data?.remarks || '-'}</Typography>
                            </Grid>
                        }
                       {!!data?.msme && msmeCertificate && (
                        <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1, borderTop: 1, borderColor: 'divider', px: 1, py: 1 }}>
                            <Typography variant="overline" component="p" sx={{ mb: 1 }}>MSME CERTIFICATE</Typography>
                            {msmeCertificate.type === "image" ? (
                                <Box sx={{ height: "120px", width: "120px", display: "inline-flex", background: theme?.palette.background.default, marginRight: "40px", borderRadius: "20px" }}>
                                    <a data-fancybox="gallery" href={cdn + msmeCertificate.path}>
                                        <img src={cdn + msmeCertificate.path} width="120" height="120" alt={`Image ${data.id + 1}`} style={{ borderRadius: "20px", objectFit: "cover" }} />
                                    </a>
                                </Box>
                            ) : (
                                <Box onClick={() => openLink(cdn + msmeCertificate.path)} sx={{ border: "1px solid #A9A9A9", width: "120px", height: "120px", display: "inline-flex", marginRight: "10px", borderRadius: "20px", justifyContent: "center", alignItems: "center" }}>
                                    <Chip label={msmeCertificate.path.split(".").pop().toUpperCase()} variant="outlined" />
                                </Box>
                            )}
                        </Grid>
                    )}

                        <Grid item xs={12} sx={{ borderRight: 1,borderBottom: 1, borderTop:1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" sx={{mb:1}}>Preferred Transport</Typography>
                            <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1 }}>
                                {
                                    transportsDetails  ?            
                                        <Box sx={{width: "100%",}}>
                                        {transportsDetails.length > 0 && transportsDetails.map((row) => (
                                            <Chip sx={{m:1}} label={row.name} key={row.id} />
                                        ))}
                                        </Box>   
                                     : <Chip sx={{m:1}} label={"No Data"} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" sx={{mb:1}}> Transports other than Mumbai</Typography>
                            <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1 }}>
                                {
                                    transportsDetails2  ?            
                                        <Box sx={{width: "100%",}}>
                                        {transportsDetails2?.length > 0 && transportsDetails2?.map((row) => (
                                            <Chip sx={{m:1}} label={row?.name} key={row.id} />
                                        ))}
                                        </Box>   
                                     : <Chip sx={{m:1}} label={"No Data"} />
                                }

                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Button variant="outlined" onClick={(event) => deleteAccount(event) }  color="error">Delete Account</Button>
                        </Grid>
                    </Grid>
                </Paper>                
            </React.Fragment>
        )
    }

    const DefaultDiscount = () => {
        return(
            <>
                <Typography variant="h6" component="h6" sx={{my: 1}}>Default Discount</Typography>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Default</Typography>
                    </Grid>
                    {data?.due_status == 1 &&
                    <>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Due Days</Typography>
                            <Typography variant="body" component="p">{`${data?.due_days} Days` || "Not available"}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Due Discount</Typography>
                            <Typography variant="body" component="p">{`${data?.due_discount} %` || "Not available"}</Typography>
                        </Grid>
                    </>}
                    {data?.nett_status == 1 &&
                        <>
                            <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Nett Days</Typography>
                                <Typography variant="body" component="p">{`${data?.nett_days} Days` || "Not available"}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Nett Discount</Typography>
                                <Typography variant="body" component="p">{`${data?.nett_discount} %` || "Not available"}</Typography>
                            </Grid>
                        </>
                    }
                    {!!data?.discount_remarks &&
                        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{ data?.discount_remarks || "Not available"}</Typography>
                        </Grid>
                    }
                </Grid>
            </Paper>
            </>
        )
    }

    const MoreAddress = () => {
        const {addresses} = data
        const AddressCard = ({address}) => {
            
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{address?.name}</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }} >
                                {address?.address_1 ? address?.address_1 : ''}
                                {address?.address_2 ? ", "+ address?.address_2 : ''}
                                {address?.area ? ", "+ address?.area : ''}
                                {address?.city_details.name ? ", "+ address?.city_details.name : ''}
                                {address?.state_details.name ? ", "+ address?.state_details.name : ''}
                                {address?.country_details.name ? ", "+ address?.country_details.name : ''}  
                                {address?.pincode ? " - "+ address?.pincode : ''}                               
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Phone</Typography>
                            <Typography variant="body" component="p" 
                            onClick={() => { if(address?.cc_phone){openLink(`tel:+${address?.cc_phone}`)}}}
                             >{`+${address?.cc_phone}` || "Not available"}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Email</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                            onClick={() => {if(address?.email){ openLink(`mailto:${address?.email}`) }}}
                            >{address?.email ? address?.email : 'Not available'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )
        }

        return (
            <>
            <Typography variant="h6" component="h6" sx={{my: 1}}>Further Addresses</Typography>
            {(addresses && addresses.length !== 0) ? 
            addresses?.map((address, index) => <AddressCard address={address} key={index} />)
            :
            <NoData />}
            </>
        )
    }

    // const Transports = ({transports, label}) => {

    //     const Item = ({transport, index}) => {
    //         return (
    //             <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', my:2 }}>
    //                 <Box sx={{padding: 2}}>

    //                     <Typography variant="h6" component="h6"  sx={{mb: 1}}>{transport.name}</Typography>
    //                     {transport.address ? <Box sx={{display: 'flex', gap: 1, mb: 1}}>
    //                     <LocationOnIcon fontSize='small'  />
    //                     <Typography variant="h6" component="h6" >{transport.address}</Typography>
    //                     </Box> : <></>}

    //                     {transport?.phone ?
    //                     <Box sx={{display: "flex", gap: 2,}}>
    //                     <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center"}}>
    //                     <PhoneIcon fontSize='small'  />
    //                     <Typography variant="h6" component="h6" >{transport?.phone && transport?.phone}</Typography>
    //                     </Box>
    //                     <Button onClick={() => { openLink(`tel:${transport.phone}`) }} variant="contained" sx={{flex: 1}}>Call</Button>
    //                     </Box> : <></>}
                        
    //                 </Box>
    //             </Paper>
    //         )
    //     };


    //     return (
    //         <>            
    //          <Typography variant="h6" component="h6">{label}</Typography>
    //             {( transports && transports?.length > 0 ) ? 
    //                 transports.map((transport, index) => <Item transport={transport} index={index} key={index} />)
    //                 :
    //                 <NoData />
    //             }
    //         </>
    //     )
    // }

    const [numbersList, setNumbersList] = useState([]);
    const [open, setOpen] = useState(false);

    function handleMultipleNumbers (numberArray)  {
        setOpen(true);
        setNumbersList(numberArray);
    }

    const Transports = ({transports, label}) => {

        const Item = ({transport, index}) => {
            let mobileNumbers = [];
            const isMultipleNumber = transport?.phone?.includes(",")
            if(isMultipleNumber){
                mobileNumbers = transport?.phone.split(',')
            }
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', my:2 }}>
                    <Box sx={{padding: 2}}>

                        <Typography variant="h6" component="h6"  sx={{mb: 2}}><b>{transport.name}</b></Typography>

                        {transport?.contact_person ? 
                        <Box sx={{display: 'flex', gap: 1, mb: 1, alignItems: "center",}}>
                        <PersonIcon fontSize='small' />
                        <Typography variant="h6" component="h6" >{transport?.contact_person?.toUpperCase()}</Typography>
                        </Box> : <></>}

                        {transport.address ? <Box sx={{display: 'flex', gap: 1, mb: 1}}>
                        <LocationOnIcon fontSize='small'  />
                        <Typography variant="h6" component="h6" >{transport.address}</Typography>
                        </Box> : <></>}                        

                        {transport?.phone ?
                        <Box sx={{display: "flex", gap: 2, mb: 1}}>
                        <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center",}}>
                        <PhoneIcon fontSize='small'  />
                        <Typography variant="h6" component="h6">{transport?.phone && transport.phone}</Typography>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={isMultipleNumber ? () => handleMultipleNumbers(mobileNumbers) :() => { openLink(`tel:${transport.phone}`) }} 
                        variant="contained" sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', }}>Call</Button>
                        </Box>
                        </Box> : <></>}

                        {transport?.email && 
                        <Box sx={{display: "flex", gap: 2, }}>
                        <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center", }}>
                        <MailIcon fontSize='small'  />
                        <Typography variant="h6" component="h6">{transport?.email && transport?.email}</Typography>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={()=> openLink(`mailto:${transport?.email}`)} 
                        variant="contained" sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', }}>Email</Button>
                        </Box>
                        </Box>}
                        
                    </Box>
                </Paper>
            )
        };


        return (
            <Box sx={{my: 2, pt:1}}>            
             <Typography variant="h6" component="h6">{label}</Typography>
                
                {(transports && transports?.length !== 0) ? 
                transports?.map((transport, index) => <Item transport={transport} index={index} key={index} />)
                :
                <NoData />
                }
            </Box>
        )
    }

    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Profile" backToHome="true">
                <Info />
                <MoreAddress />
                { portal == "supplier" && (data?.due_status == 1 || data?.nett_status == 1) && <DefaultDiscount />}
                <>
                <Typography variant="h6" component="h6" sx={{my: 1}}>Contacts</Typography>
                {
                    (data?.contacts && data?.contacts.length !== 0) ? data?.contacts.map((row) => (
                        <Contact key={row.id} data={row} />
                    )) 
                    : 
                    <NoData />
                }
                </>
                <Transports transports={transportsDetails} label={"Transports"} />
                <Transports transports={transportsDetails2} label={"Transports other than Mumbai"} />

                <Dialog  onClose={()=> {setOpen(false); setNumbersList([]);}} open={open}>
                <DialogTitle >
                   <Typography sx={{fontSize: "20px"}}>Contact Numbers</Typography>
                </DialogTitle>
                <List>
                    {numbersList?.map( (number, index) => {
                        if(number){
                            return  <ListItem key={index} >
                                        <ListItemButton onClick={() => {openLink(`tel:${number.trim()}`)} } >
                                        <ListItemIcon >
                                            <PhoneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={number.trim()} />
                                        </ListItemButton>
                                    </ListItem>} 
                        }
                     )}
                </List>                
                </Dialog>

            </Layout>
        }
        </>
    );
}

export default Profile;
