import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import UploadButton from '../../components/VoucherUploader';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns'

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { red, amber, green } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import TicketsGlobal from '../../components/TicketsGlobal';
import MediaGrid from '../../components/MediaGrid';
import Chatter from '../../components/Chatter';
import { Button, Divider, useMediaQuery } from '@mui/material';
import LoadingComponent from '../../components/LoadingComponent';

function Order({forceView = false}) {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, cdn, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [title, setTitle] = useState("Order");

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Order View");
        }
    }, [ ]);

    const fetchData = async () => {
        await axios.post( url + 'api/voucher', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log({dataReceived: response.data})
            setData(response.data);
            if(response?.data?.voucher?.is_catalog){
                setTitle("Catalog Order")
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Info = () => {
        if (data.hasOwnProperty('voucher')) { 
        return (
            <>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                        <Typography variant="h6" component="h6">{ portal == "supplier" ? data?.voucher?.buyer_name : data?.voucher?.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data?.voucher?.buyer_city : data?.voucher?.seller_city }</span></Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Order #</Typography>
                            <Typography variant="body" component="p">{data?.voucher?.voucher_no ? data?.voucher?.voucher_no : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Date</Typography>
                            <Typography variant="body" component="p">{data?.voucher?.date ? format(parse(data?.voucher?.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377; {data?.voucher?.total ? data?.voucher?.total : ''}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderBottom: 1,borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Delivery From</Typography>
                            <Typography variant="body" component="p">{data.summary.delivery_from ? format(parse(data.summary.delivery_from, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderBottom: 1,borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Delivery To</Typography>
                            <Typography variant="body" component="p">{data.summary.delivery_to ? format(parse(data.summary.delivery_to, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.voucher?.remarks ? data?.voucher?.remarks : '-'}</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                        <MediaGrid dir={data?.voucher?.attachment_directory} images={data?.voucher?.images} />
                    </Box>
                </Paper>

                { portal == "supplier" && (parseInt(data?.voucher?.order_status) == 0 || parseInt(data?.voucher?.order_status) == 1) &&
                    <UploadButton title="Invoice" buyer={data?.voucher?.buyer} seller={data?.voucher?.seller} link={data?.voucher?.id} voucher_type="invoices" reloadRequired={true} />
                }
            </>
        )
        } else {
            return (
                <Card sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                    <Typography variant="overline" component="p">Loading...</Typography>
                </Card>
            )
        }
    }

    const ItemRow = (props) => {
        const rowBackgroundColors = {
            "excess": amber[100], // just for example, remove it if you don't need
            "match": green[100],
            "less": red[100],
          };

        const { row, index } = props;
        const [open, setOpen] = React.useState(false);

        if(!row.hasOwnProperty('invoiced')){
            row.invoiced = 0;
        }
        var bgClass = "less";
        if(parseFloat(row.invoiced) == parseFloat(row.qty)) {
            bgClass = "match";
        } else if(parseFloat(row.invoiced) > parseFloat(row.qty)) {
            bgClass = "excess";
        }

        if(row.image == null) {
            var images = [];
        } else {
            var images = JSON.parse(row.image);
        }

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: rowBackgroundColors[bgClass] }}>
              <TableCell>
                <IconButton sx={{color:"#000"}} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <IconChevronUp /> : <IconChevronDown />}</IconButton>
              </TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{index}</TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{row.particular}</TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{row.category_details?.name || ""}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{row.rate}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{row.qty}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{row.invoiced}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">Details</Typography>
                    <Grid container>
                        {
                            JSON.parse(row.attributes).length > 0 &&
                            JSON.parse(row.attributes).map( attribute => {
                                return (
                                    <>
                                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                                            <Typography variant="overline" component="p">{attribute.name}</Typography>
                                            <Typography variant="body" component="p">{attribute.value}</Typography>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount (%)</Typography>
                            <Typography variant="body" component="p">{row.discount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total Discount (&#8377;)</Typography>
                            <Typography variant="body" component="p">&#8377;{row.total_discount_amount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Tax (%)</Typography>
                            <Typography variant="body" component="p">{row.tax}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377;{row.subtotal}</Typography>
                        </Grid>
                        {
                            Object.keys(images).length ?
                            <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                                <img style={{ width: 140+"px", height: "auto", borderRadius: "4px" }} src={cdn + 'catalog/' + images[0].file} data-fancybox="products" href={cdn + 'catalog/' + images[0].file}/>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                    
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        );
    }

    const OrderFormItems = ({item, index}) => {

        const [open, setOpen] = useState(false);

        const rowBackgroundColors = {
            excess: amber[100],
            match: green[100],
            less: red[100],
          };
          
        item.invoiced = item.invoiced ?? 0;
        
        const bgClass = 
            parseFloat(item?.invoiced) === parseFloat(item?.qty) ? "match" :
            parseFloat(item?.invoiced) > parseFloat(item?.qty) ? "excess" :
            "less";
        
        const images = item?.image ? JSON.parse(item.image) : [];

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: rowBackgroundColors[bgClass] }}>
              <TableCell>
                <IconButton sx={{color:"#000"}} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <IconChevronUp /> : <IconChevronDown />}</IconButton>
              </TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{index+1}</TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{item?.particular}</TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{item?.attributes[0]?.value || ""}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{"\u20B9"} {item?.rate}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{item?.qty}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{item?.invoiced}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">Details</Typography>
                    <Grid container>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount (%)</Typography>
                            <Typography variant="body" component="p">{item?.discount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total Discount (&#8377;)</Typography>
                            <Typography variant="body" component="p">&#8377;{item?.totalDiscountAmount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Tax (%)</Typography>
                            <Typography variant="body" component="p">{item?.tax}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377;{item?.subTotal}</Typography>
                        </Grid>
                        
                        {
                            Object.keys(images).length ?
                            <Grid item xs={12} md={3} sx={{ px: 1, py:1 }}>
                                <img style={{ width: 140+"px", height: "auto", borderRadius: "4px" }} src={cdn + 'catalog/' + images[0]?.file} data-fancybox="products" href={cdn + 'catalog/' + images[0].file}/>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                    
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        );

    }

    const FloatDetails = () => {
        console.log({floatDetail: data.voucher})
        
        return (
            <Paper sx={{ border: 1, borderBottom: 1,  borderColor: 'divider' }}>
                <Box sx={{ borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                    <Typography variant="overline" component="p" sx={{textAlign: "center"}}>{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                    <Typography variant="h6" component="h6" sx={{textAlign: "center"}}>{ portal == "supplier" ? data?.voucher?.buyer_name : data?.voucher?.seller_name }</Typography>  
                </Box>
                <Box sx={{display: "flex", width: "100%"}}>
                    <Box sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1, flex: 1 }}>
                        <Typography variant="overline" component="p" sx={{textAlign: "center"}} >Order #</Typography>
                        <Typography variant="body" component="p" sx={{textAlign: "center"}}>{ data?.voucher?.voucher_no || "-" }</Typography>
                    </Box>
                    <Box  sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1, flex: 1 }}>
                        <Typography variant="overline" component="p" sx={{textAlign: "center"}}>Date</Typography>
                        <Typography variant="body" component="p" sx={{textAlign: "center"}}> {data?.voucher?.date ? format(parse(data?.voucher?.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Box>
                    <Box  sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1, flex: 1 }}>
                        <Typography variant="overline" component="p" sx={{textAlign: "center"}}>Total</Typography>
                        { data?.voucher?.total ? 
                        <Typography variant="body" component="p" sx={{textAlign: "center"}}>&#8377; {data?.voucher?.total} </Typography> 
                        : 
                        <Typography variant="body" component="p" sx={{textAlign: "center"}}>-</Typography>
                        }
                    </Box>
                </Box>
                <Box sx={{ borderColor: 'divider', px: 1, py:1 }}>
                    <Typography variant="overline" component="p" sx={{textAlign: "center"}}>Remarks</Typography>
                    <Typography variant="body" component="p" sx={{textAlign: "center"}}>{data?.voucher?.remarks ? data?.voucher?.remarks : '-'}</Typography>
                </Box>
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir={data?.voucher?.attachment_directory} images={data?.voucher?.images} />
                </Box>
            </Paper>
        )
    }

    const Invoice = (prop) => {
        
        if(!!prop?.data?.summary && typeof prop?.data?.summary === 'string') {
            prop.data.summary = JSON.parse(prop.data.summary);
        }
        return (
            <Card sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Grid container >
                    { prop.type == 0 ?
                        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Chip label="Unprocessed" />
                        </Grid>
                        : 
                        <></>
                    }
                    <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Invoice #</Typography>
                        <Typography variant="body" component="p">{prop.data.voucher_no ? prop.data.voucher_no : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{prop.data.date ? format(parse(prop.data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {prop.data.total ? prop.data.total : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Due</Typography>
                        <Typography variant="body" component="p">&#8377; {prop.data.summary ? (prop.data.summary.due ? prop.data.summary.due : '') : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Due Date</Typography>
                        <Typography variant="body" component="p">{prop.data.summary ? (prop.data.summary.due_date ? format(parse(prop.data.summary.due_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '') : '-'}</Typography>
                    </Grid>
                    {!!prop?.data?.remarks &&
                        <Grid item xs={12} sx={{ borderTop: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{prop?.data?.remarks || '-'}</Typography>
                        </Grid>
                    }
                </Grid>
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir={prop.data.attachment_directory} images={prop.data.images} />
                </Box>
                {
                    prop?.type != 0 && 
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', p:1 }}>
                        <Button variant="contained" fullWidth onClick={(event) => { if(prop.type != 0) { navigate("/invoice/" + prop.data.id) } }}>View Invoice</Button>
                    </Box>
                }
            </Card>
        )
    }

    const Invoices = () => {
        return (
            <>
                <Typography variant="h6" component="div">Invoices</Typography>
                {data.entries?.map((row, index) => (
                    <Invoice type={0} key={index} data={row}></Invoice>
                ))}
                {data.invoices?.map((row, index) => (
                    <Invoice type={1} key={index} data={row}></Invoice>
                ))}

                {
                    (Object.keys(data.entries).length + Object.keys(data.invoices).length) == 0 ?
                        <Typography variant="caption" component="div" sx={{mb:4}}>No invoice has been added yet</Typography>
                        :
                        null

                }
            </>
        )
    }

    const isMobile = useMediaQuery('(max-width:600px)');

    const Address = () => {

        const [address, setAddress] = useState("billing")

        const BillingAddress = () => {
            if(data.voucher?.billing_address === null){
                return <></>
            }
            return(
                <>
                    { isMobile ? <></> : <Typography variant="subtitle1" component="p" sx={{textDecoration: "underline", marginBottom: "5px"}}>Billing Address</Typography>}
                    <Typography variant="body" component="p">Name: {data.voucher?.billing_address?.name}</Typography>
                    <Typography variant="body" component="p">Address Line 1: {data.voucher?.billing_address.address_1}</Typography>
                    <Typography variant="body" component="p">Address Line 2: {data.voucher?.billing_address.address_2}</Typography>
                    <Typography variant="body" component="p">Area: {data.voucher?.billing_address.area}</Typography>
                    <Typography variant="body" component="p">City: {data.voucher?.billing_address.city_details.name}</Typography>
                    <Typography variant="body" component="p">State: {data.voucher?.billing_address.state_details.name}</Typography>
                    <Typography variant="body" component="p">Country: {data.voucher?.billing_address.country_details.name}</Typography>
                    <Typography variant="body" component="p">PIN: {data.voucher?.billing_address.pincode}</Typography>
                    <Typography variant="body" component="p">Phone: {data.voucher?.billing_address.cc_phone}</Typography>
                </>
            )
        }        

        const ShippingAddress = () => {
            if(data.voucher?.shipping_address === null){
                return <></>
            }
            return (
                <>
                    { isMobile ? <></> :  <Typography variant="subtitle1" component="p" sx={{textDecoration: "underline", marginBottom: "5px"}}>Shipping Address</Typography>}
                    <Typography variant="body" component="p">Name: {data.voucher?.shipping_address?.name}</Typography>
                    <Typography variant="body" component="p">Address Line 1: {data.voucher?.shipping_address.address_1}</Typography>
                    <Typography variant="body" component="p">Address Line 2: {data.voucher?.shipping_address.address_2}</Typography>
                    <Typography variant="body" component="p">Area: {data?.voucher?.shipping_address.area}</Typography>
                    <Typography variant="body" component="p">City: {data.voucher?.shipping_address.city_details.name}</Typography>
                    <Typography variant="body" component="p">State: {data.voucher?.shipping_address.state_details.name}</Typography>
                    <Typography variant="body" component="p">Country: {data.voucher?.shipping_address.country_details.name}</Typography>
                    <Typography variant="body" component="p">PIN: {data?.voucher?.shipping_address.pincode}</Typography>
                    <Typography variant="body" component="p">Phone: {data.voucher?.shipping_address.cc_phone}</Typography> 
                </>
            )
        }

        return (            
            <>
            <Typography  variant="h6" component="div" sx={{mb:2, mt:2}}>Address</Typography>
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', mt: 1, mb: 4, p: 1 }}>
                { isMobile ? 
                <> 
                <Box sx={{display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                <Chip label="Billing Address"  onClick={() => setAddress("billing")} color="primary" variant={ (address === "shipping" ? "outlined" : "filled")} />
                <Chip label="Shipping Address"  onClick={() => setAddress("shipping")} color="primary" variant={ (address === "billing" ? "outlined" : "filled")} />
                </Box>

                <Box sx={ {padding: "5px"} }>
                    { (address === "billing") ? <BillingAddress /> : <ShippingAddress /> }
                </Box> 
                </> 
                : 
                <>
                <Box sx={ {display: "flex", padding: "5px"} }>
                    <Box sx= {{flex: 1,  padding: "10px"}}>
                        <BillingAddress />                    
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx= {{flex: 1, padding: "10px"}}>
                        <ShippingAddress />                                       
                    </Box>
                </Box>
                </> }
            </Paper>
            </>         
        )
    }

    const OrderDetails = () => {
        if(forceView == true) {
            return (
                <>
                    <Info />
                    { data.voucher?.billing_address !== null && data.voucher?.shipping_address !== null &&<Address />}
                    <Items />
                    <Invoices />
                    <TicketsGlobal seller={data?.voucher?.seller} buyer={data?.voucher?.buyer} area="clients_vouchers" id={data?.voucher?.id} />
                </>
            )
        }

        if(data?.voucher?.order_type == 4 && data?.voucher?.chat_channel != null) {
            setTitle("Custom Order");
            return (
                <>
                    <Chatter id={data?.voucher?.chat_channel} FloatDetails={FloatDetails} />
                </>
            )
        } else {
            return (
                <>
                    <Info />
                    { data.voucher?.billing_address !== null && data.voucher?.shipping_address !== null &&<Address />}
                    <Items />
                    <Invoices />
                    <TicketsGlobal seller={data?.voucher?.seller} buyer={data?.voucher?.buyer} area="clients_vouchers" id={data?.voucher?.id} />
                </>
            )
        }
    }
    
    const Items = () => {
        return (
            <Box>
                <>
                    <TableContainer component={Paper} sx={{ my: 2 }}>
                        <Table stickyHeader aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                <TableCell width="30px" />
                                <TableCell width="50px" align="left" size="small">No.</TableCell>
                                <TableCell align="left">Particular</TableCell>
                                { !data?.voucher?.entry_id ? 
                                    <TableCell align="left">Size</TableCell>
                                    : 
                                    <TableCell align="left">Category</TableCell> 
                                }
                                <TableCell align="right">Rate</TableCell>
                                <TableCell align="right">Qty</TableCell>
                                <TableCell align="right">Invoiced</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {!!data?.voucher?.clients_voucher_items && data?.voucher?.clients_voucher_items?.length > 0 &&
                                data?.voucher?.clients_voucher_items?.map((row, index) => (
                                    <ItemRow key={row.sno} row={row} index={index+1} />
                                ))
                            }

                            { !data?.voucher?.entry_id && data?.voucher?.items &&

                                JSON.parse(data?.voucher?.items)?.map((item, index) => {
                                    return <OrderFormItems key={index+1} item={item} index={index} />
                                } )
                            }

                            {(data?.summary?.totalQty || data?.summary?.total) &&
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Box sx={{width: "100%", padding: "10px", display: "flex", flexDirection: "row"}}>
                                            {data?.summary?.totalQty != null &&
                                            <Box sx={{ flex: 1}}>
                                                <Typography variant="body2" sx={{textAlign: "center"}}>Total Qty: {data?.summary?.totalQty}</Typography>
                                            </Box>}
                                            {data?.summary?.total != null &&
                                            <Box sx={{ flex: 1}}>
                                                <Typography variant="body2" sx={{textAlign: "center"}}>Total Amount: &#8377;{data?.summary?.total}</Typography>
                                            </Box>}                                    
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                        <Chip label="Pending" sx={{color: "#000", backgroundColor: red[100]}} />
                        <Chip label="Invoiced" sx={{color: "#000", backgroundColor: green[100]}} />
                        <Chip label="Excess Invoiced" sx={{color: "#000", backgroundColor: amber[100]}} />
                    </Box>
                </>            
            </Box>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title={title}>
                <OrderDetails />
            </Layout>
        }
        </div>
    );
}

export default Order;