import { Box, Grid, Paper, Tab, Tabs, Typography, Button, Chip } from "@mui/material";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import ErrorMessage from "../../components/ErrorMessage";
import FilterForm from "../../components/FilterForm";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import MediaGrid from "../../components/MediaGrid";
import ScrollComponent from "../../components/ScrollComponent";
import UploadMedia from "../../components/UploadMedia";
import Void from "../../components/Void";
import UploadButton from "../../components/VoucherUploader";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function CreditNotesPage() {

    let { status } = useParams();
    const navigate = useNavigate();
    const { url,customer, token, portal, contact, phone } = useContext(GlobalContext);
    const title = "Credit Notes";

    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/creditnotes?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            status: pageStatus,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['creditNotes', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    ); 
    
    const Item = ({data}) => {

        var voided = 0;
        if(status == 0) {
            if(data.status == 6) {
                voided = 1;
            }
        } else {
            if(data.status == 3) {
                voided = 1;
            }
        }
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal === "buyer" ? "Supplier" : "Buyer"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container sx={{ borderColor: 'divider' }}>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Credit Note #</Typography>
                        <Typography variant="body" component="p">{data.voucher_no ? data.voucher_no : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        {data?.total ? 
                            <Typography variant="body" component="p">&#8377; {data.total}</Typography> :
                            <Typography variant="body" component="p">{'-'}</Typography>
                        }
                    </Grid>
                    {data?.remarks &&
                        <Grid item xs={12} sx={{borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.remarks ?? '-'}</Typography>
                        </Grid>
                    }
                </Grid>
                <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir={data?.attachment_directory} images={data?.images} />
                </Box>
                {
                    status == 0 && (portal == "supplier" || portal == "buyer") ?
                    <Box sx={{ px: 1, marginTop: "5px", display: "flex", gap: 1, }}>
                        <Box sx={{flex: 1,}} >
                            <UploadMedia
                                id={data?.id} module="entry"
                                dir={data?.attachment_directory} link={url + 'api/media/add'}
                                refetch={refetch}                                
                            />
                        </Box>
                        <Box sx={{flex:1}} >
                            <Void refetch={refetch} id={data?.id} type={ status == 0 ? 'entry' : 'voucher' } voided={ voided } />
                        </Box>
                    </Box> 
                    :
                    <> 
                    {data?.status_label !==  "voided" &&
                        <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", gap: 1 }}>
                            <Button variant="contained" onClick={() => { if(status == 1) { navigate("/creditnote/" + data.id) } }} fullWidth >View</Button>
                            <Void refetch={refetch} id={data?.id} type={ status == 0 ? 'entry' : 'voucher' } voided={ voided } />
                        </Box>
                    }
                    </>
                }
                
            </Paper>
        )
    }

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "CreditNotes", status: { 0: "Unproccessed", 1: "Credit notes", 99: "All Data",}};

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={ isLoading } /> 
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/creditnotes/" + newStatus) }}>
                            <Tab label="Unprocessed" />
                            <Tab label="Credit Notes" />
                            <Tab label="Voided" />
                        </Tabs>

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    </Box>

                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_creditnotes"} />

                    <UploadButton buttonType="fab" title="Credit Note" voucher_type="creditnotes" refetch={refetch}
                        createdInAreas={[ 
                            {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                            {label : "Add Button"},
                        ]}
                    />

                    <BackToTop />                  
                    
                </Layout>
            }
            </>
        }
        </>
    )
}