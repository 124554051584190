import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";

export const GlobalContext = React.createContext();
export const GlobalContextProvider = ({ children }) => {
    const wsHost = "ws.rasiklalsons.in";
    let urlTemp;
    let cdnTemp;

    // if (process.env.REACT_APP_DEV_MODE === "development"){
    //     // urlTemp = "https://rasiklal.93lab.com/";
    //     // urlTemp = "https://rs.awpws.in/";
    //     urlTemp = "https://www.rs-stage.awpws.in/"
    //     cdnTemp = "https://cdn.rasiklalsons.in/stg/";
    // } else if (process.env.REACT_APP_DEV_MODE === "production"){
    //     urlTemp = "https://erp.rasiklalsons.in/";
    //     cdnTemp = "https://cdn.rasiklalsons.in/prod/";
    // }

    if (process.env.REACT_APP_DEV_MODE === "development"){
        // urlTemp = "https://rasiklal.93lab.com/";
        // urlTemp = "https://rs.awpws.in/";
        // urlTemp = "http://192.168.68.75:9010/";
        // urlTemp = "https://rs-s.awpws.in/";
        urlTemp = "https://80--main--rasiklal-erp--tamil.awpws.in/";
        // urlTemp = "http://192.168.68.76:9030/";
        // urlTemp = "http://192.168.68.74:9010/";

        // urlTemp = "https://www.rs-stage.awpws.in/";
        
        cdnTemp = "https://cdn.rasiklalsons.in/stg/";
    }else if(process.env.REACT_APP_DEV_MODE === "beta"){ 
        urlTemp = "https://rs.app.awp.dev/"
        cdnTemp = "https://cdn.rasiklalsons.in/stg/";
    }else if (process.env.REACT_APP_DEV_MODE === "production"){
        urlTemp = "https://erp.rasiklalsons.in/";
        cdnTemp = "https://cdn.rasiklalsons.in/prod/";
    }
    const url = urlTemp;
    const cdn = cdnTemp

    const wsKey = "rasiklal-sock-key";
    const wsPort = 443;
    const ios_version = "1.9.5";
    const android_version = "1.9.5";
    const web_version = "1.9.5";

    //loggedIn
    const [loggedIn, setLoggedIn] = useState ( () => {
        const savedItem = localStorage.getItem("loggedIn");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("loggedIn", loggedIn)
    }, [loggedIn]);

    //multi
    const [multi, setMulti] = useState ( () => {
        const savedItem = localStorage.getItem("multi");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("multi", multi)
    }, [multi]);

    //sessionToken
    const [sessionToken, setSessionToken] = useState ( () => {
        const savedItem = localStorage.getItem("sessionToken");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("sessionToken", sessionToken)
    }, [sessionToken]);

    //token
    const [token, setToken] = useState ( () => {
        const savedItem = localStorage.getItem("token");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("token", token)
    }, [token]);

    //customer
    const [customer, setCustomer] = useState ( () => {
        const savedItem = localStorage.getItem("customer");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("customer", customer)
    }, [customer]);

    //customerName
    const [customerName, setCustomerName] = useState ( () => {
        const savedItem = localStorage.getItem("customerName");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("customerName", customerName)
    }, [customerName]);

    //contact
    const [contact, setContact] = useState ( () => {
        const savedItem = localStorage.getItem("contact");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("contact", contact)
    }, [contact]);

    //phone
    const [phone, setPhone] = useState ( () => {
        const savedItem = localStorage.getItem("phone");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("phone", phone)
    }, [phone]);

    //pushToken
    const [pushToken, setPushToken] = useState ( () => {
        const savedItem = localStorage.getItem("pushToken");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("pushToken", pushToken)
    }, [pushToken]);

    //ledgers
    const [ledgers, setLedgers] = useState ( () => {
        const savedItem = localStorage.getItem("ledgers");
        const parsedItem = savedItem;
        return JSON.parse(parsedItem) || '';
    });

    useEffect(() => {
        localStorage.setItem("ledgers", JSON.stringify(ledgers))
    }, [ledgers]);

    //invoices
    const [invoices, setInvoices] = useState ( () => {
        const savedItem = localStorage.getItem("invoices");
        const parsedItem = savedItem;
        return JSON.parse(parsedItem) || '';
    });

    useEffect(() => {
        localStorage.setItem("invoices", JSON.stringify(invoices))
    }, [invoices]);

    //portal
    const [portal, setPortal] = useState ( () => {
        const savedItem = localStorage.getItem("portal");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    const getUniqueByKey = function(array, key) {
        return array.reduce((acc, obj) => {
          const value = obj[key];
          return acc.some(o => o[key] === value) ? acc : [...acc, obj];
        }, []);
    }

    useEffect(() => {
        localStorage.setItem("portal", portal)
    }, [portal]);

    //portal
    const [masters, setMasters] = useState ( () => {
        const savedItem = localStorage.getItem("masters");
        const parsedItem = savedItem;
        return JSON.parse(parsedItem) || '';
    });

    useEffect(() => {
        localStorage.setItem("masters", JSON.stringify(masters))
    }, [masters]);

    if (customer){
        Sentry.configureScope(scope => {
            scope.setUser({
                'id': customer,
                'name': customerName,
                'contact_id': contact,
                'portal': portal == 2 ? "Supplier" : "Buyer",
            });
        });
    }
    return (
        <GlobalContext.Provider value={{ url, cdn, ios_version, android_version, web_version, wsHost, wsKey, wsPort, loggedIn, setLoggedIn, multi, setMulti, token, setToken, customer, setCustomer, customerName, setCustomerName, contact, setContact, phone, setPhone, pushToken, setPushToken, invoices, setInvoices, ledgers, setLedgers, portal, setPortal, masters, setMasters, sessionToken, setSessionToken, getUniqueByKey }}>
            {children}
        </GlobalContext.Provider>
    );
};