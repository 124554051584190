import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadButton from '../../components/VoucherUploader';
import Fab from '@mui/material/Fab';
import { IconPlus, IconSend } from '@tabler/icons-react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import LoadingComponent from '../../components/LoadingComponent';
import { LoadingButton } from '@mui/lab';

function SupplierForms() {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
    }, []);

    const fetchData = () => {
        var page = currentPage + 1;
        axios.post( url + 'api/suppliers/chat/forms?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems((prev) => [...prev, ...response.data.data]);
            setCurrentPage(parseInt(response.data.current_page));
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Item = (prop) => {
        return (
            <Card onClick={() => { navigate("/supplier/forms/manage/" + prop.data.id) }} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 2 }}>
                <Box sx={{ px: 2, py:2 }}>
                    <Typography variant="subtitle2" component="h6">{prop.data.name}</Typography>
                </Box>
            </Card>
        )
    }

    function New() {
        const [open, setOpen] = useState(false);
        const [name, setName] = useState("");
        const [loading, setLoading] = useState(false);

        const handleOpen = () => {
            setOpen(true);
        }

        const handleClose = () => {
            setOpen(false);
        }

        const handleSubmit = (event) => {
            event.preventDefault();
            if(!!name){
                setLoading(true)
                axios.post( url + 'api/suppliers/chat/forms/add', {
                    customer: customer,
                    contact: contact,
                    phone: phone,
                    name: name
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    if(response?.data?.error == 0){
                        window.location.reload();
                    }
                    handleClose()
                    // navigate("/supplier/forms/manage/" + response.data.id);
                })
                .catch(function (error) {
                    console.log({error});
                })
                .finally(() => {
                    setLoading(false);
                });
            }else{
                alert("Please enter a valid order form name!")
            }
        }

        return(
            <React.Fragment>
                <Fab color="primary" aria-label="add" onClick={handleOpen} size={"medium"} sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
                    <IconPlus />
                </Fab>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>New Form</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                        <form onSubmit={handleSubmit}>
                            <InputLabel shrink>Name</InputLabel>
                            <TextField sx={{ mb: 2 }} 
                                required
                                fullWidth 
                                size={"small"}
                                value={name}
                                placeholder={"Enter the new order form name"}
                                onChange={event => {
                                    setName(event?.target?.value);
                                }} 
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, }}>
                                {/* <Button type="submit" variant="contained">Save</Button> */}
                                <LoadingButton type="submit" variant="contained" loading={loading} >Save</LoadingButton>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Order Forms">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {items?.map((row, index) => (
                        <Item key={row?.id} data={row}></Item>
                    ))}
                    </InfiniteScroll>
                </Box>
                <New />
            </Layout>
        }
        </div>
    );
}

export default SupplierForms;
