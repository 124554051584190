import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Card from '@mui/material/Card';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingComponent from '../../components/LoadingComponent';
import NoData from '../../components/NoData';

function BuyerCatalog() {
    let { status } = useParams();
    const navigate = useNavigate();
    const { url, phone, contact, customer, token,} = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [level, setLevel] = useState(4);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(0);

    const handleChange = (event, newStatus) => {
        setItems([]);
        navigate("/buyer/catalog/" + newStatus)
    };

    useEffect(() => {
        setItems([]);
        setLoading(true)
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Buyer Catalog");
        }
    }, [ status ]);

    useEffect(() => {
        if(status == 0){
            let tempCategories = [];
            items.forEach(item => {
                let i=1;
                let category = item.category_details;    
                while(i<level){
                    category = category?.parent;
                    i++;
                }
                tempCategories.push(category);
            });
            tempCategories = tempCategories.filter((item) => item?.parent_id == category);
            setCategories([
                ...new Map(tempCategories.map((item) => [item?.id, item])).values(),
            ]);
        }else{
            setCategories(items);
        }
    }, [items, level, category]);

    const fetchData = () => {
        axios.post( url + 'api/buyers/catalog/categories', {
            status: status,
            customer: customer,
            contact: contact,
            phone: phone,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems(response.data.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Item = (prop) => {
        const [data, setData] = useState(null);

        useEffect(() => {
            setData(prop.data);
        }, []);

        return (
            <>
            <Card onClick={() => {
                    if (status == 0) {
                        if (level == 1) {
                            navigate("/buyer/catalog/products/" + data.id + "/0");
                        } else {
                            setLevel(level - 1);
                            setCategory(data.id);
                        }
                    } else {
                        navigate("/buyer/catalog/categories/" + data.supplier);
                    }
                }
            }
            sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 2, borderRadius: 2}} elevation={2}>
                <Box sx={{ px: 2, py:3,}}>
                    { status == 0 &&
                    <Typography variant="subtitle2" component="p">{data?.name}</Typography>
                    }
                    { status == 1 &&
                    <Typography variant="subtitle2" component="p">{data?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{data?.supplier_details?.city_details?.name}</span></Typography>
                    }
                </Box>
            </Card>
            </>
        )
    }

    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Catalog" backToHome="true">
                <Box sx={items?.length > 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>
                    <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={handleChange}>
                        <Tab label="All Categories" />
                        <Tab label="Suppliers" />
                    </Tabs>

                    {items?.length > 0 ?
                        <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '12px' }}>You have reached end of the list</p>}>
                            {categories.map((item, index) => (
                                <Item key={index} data={item}></Item>
                            ))}
                        </InfiniteScroll>
                        :
                        <Box sx={{mt:3}}>
                            <NoData />
                        </Box>
                    }
                </Box>
            </Layout>
        }
        </>
    );
}

export default BuyerCatalog;
