import { Alert, AlertTitle, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../components/BackToTop";
import ErrorMessage from "../../../components/ErrorMessage";
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import MediaGrid from "../../../components/MediaGrid";
import ScrollComponent from "../../../components/ScrollComponent";
import UploadMedia from "../../../components/UploadMedia";
import Void from "../../../components/Void";
import { FilterDataContext } from "../../../contexts/FilteredDataContext";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function FilteredCreditNotes() {

    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, filterDataQuery } = useContext(FilterDataContext);

    const { url, customer, token, portal, contact  } = useContext(GlobalContext);

    const title = "Filtered Credit Notes";

    const currentTab = { name: "CreditNotes", status: { 0: "Unproccessed", 1: "Credit notes", 99: "All Data",}};

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/vouchers/search?page=' + page, {
            customer: customer,
            contact: contact,
            buyer: portal == "buyer" ? customer : filterDataBuyer?.id,
            seller: portal == "supplier" ? customer : filterDataSupplier?.id,
            status: filterDataStatus==="allData" ? "" : filterDataStatus.toString(),
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            creator: portal == "supplier" ? 2 : 3,               
            query: filterDataQuery,
            voucher_type: "creditnotes",
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredCreditNotes',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const Item = ({data}) => {

        var voided = 0;
        if(filterDataStatus == 0 || data?.status_label == "Unproccessed") {
            if(data.status == 6) {
                voided = 1;
            }
        } else {
            if(data.status == 3) {
                voided = 1;
            }
        }
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal === "buyer" ? "Supplier" : "Buyer"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container sx={{ borderColor: 'divider' }}>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Credit Note #</Typography>
                        <Typography variant="body" component="p">{data.voucher_no ? data.voucher_no : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        {data?.total ? 
                            <Typography variant="body" component="p">&#8377; {data.total}</Typography> :
                            <Typography variant="body" component="p">{'-'}</Typography>
                        }
                    </Grid>
                    {data?.remarks &&
                        <Grid item xs={12} sx={{borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.remarks ?? '-'}</Typography>
                        </Grid>
                    }
                </Grid>
            
                <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir={data?.attachment_directory} images={data?.images} />
                </Box>
                {
                    filterDataStatus == 1 || data?.status_label == "Credit note" && 
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", gap: 1 }}>
                        <Button variant="contained" onClick={() => { if(filterDataStatus == 1 || data?.status_label == "Credit note") { navigate("/creditnote/" + data?.id) } }} fullWidth >View</Button>
                        <Void refetch={refetch} id={data?.id} type={ filterDataStatus == 0 || data?.status_label == "Unproccessed" ? 'entry' : 'voucher' } voided={ voided } />
                    </Box>
                }
                
            </Paper>
        )
    }

    if(!filterDataBuyer && !filterDataSupplier){
        navigate(-1, { replace: true })
        return null;
    }

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>            
            {  isLoading ? 
            <LoadingComponent loading={isLoading} />
            : 
            <Layout title={title} backToTop={true}>                
                <Box>
                    <Alert severity="success" >
                        <AlertTitle sx={{fontSize: "0.5 em"}}> Results {portal === "buyer" ? filterDataSupplier && `for ${(filterDataSupplier?.name?.toUpperCase())}` : filterDataBuyer && `for ${(filterDataBuyer?.name?.toUpperCase())}`}</AlertTitle>
                        {(filterDataFromDate && filterDataToDate) && 
                        ` From: ${format(new Date(filterDataFromDate), 'dd/MM/yyyy')} To: ${format(new Date(filterDataToDate), 'dd/MM/yyyy')}`
                        }    
                        <Box sx={{mt: 1}}>
                        {`Status: ${currentTab.status[1]}`}
                        </Box>
                        {!!filterDataQuery &&  
                            <Box sx={{mt: 1}}>
                                {`Search Query: ${filterDataQuery}`}
                            </Box>
                        }
                    </Alert>                    
                </Box>                
                <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                <BackToTop alone={true} />
            </Layout>
            }
            </>
        }
        </>
    )
}