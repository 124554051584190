import { Alert, AlertTitle, Button, Chip, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../components/BackToTop";
import CheckinButton from "../../../components/CheckinButton";
import ErrorMessage from "../../../components/ErrorMessage";
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import MediaGrid from "../../../components/MediaGrid";
import ScrollComponent from "../../../components/ScrollComponent";
import Void from "../../../components/Void";
import UploadButton from "../../../components/VoucherUploader";
import { FilterDataContext } from "../../../contexts/FilteredDataContext";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function FilteredCustomOrders() {

    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, } = useContext(FilterDataContext);
    
    const { url, customer, token, portal  } = useContext(GlobalContext);

    const title = "Filtered Custom Orders";

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/customorders?page=' + page, {
            buyer: portal === "buyer" ? customer : filterDataBuyer?.id,
            supplier: portal === "supplier" ? customer : filterDataSupplier?.id,
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            status: filterDataStatus==="allData" ? "" : filterDataStatus.toString(),
            customer
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredCustomOrders',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const currentTab = { name: "CustomOrders", status: {  1: "Ordered", 2: "Completed", 4: "Voided", 99: "All Data",}};

    const Item = ({data}) => {

        var reason = '';
        if(data?.approval == 2 && data?.approval_log) {
            reason = JSON.parse(data?.approval_log)[0]?.reason || "-";
        }

        function redirectOnClick () {
            if((data?.status_label ==="Ordered") || (data?.status_label ==="Completed")) { 
                return navigate("/order/" + data?.id) 
            }
            else{
                return navigate("/order/" + data?.voucher_id) 
            }
        }
        
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer',width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box>

                    { filterDataStatus === "allData" && data?.status_label && 
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}> <Chip  label={data.status_label}  /> </Box>
                    }   
                                     
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                        <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                        {
                            portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                        }
                        {
                            portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                        }
                    </Box>
                    <Grid container>
                        {
                            ( data?.status_label ==="Ordered" || data?.status_label ==="Completed" || data?.status_label ==="Voided" ) ?
                            <>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order #</Typography>
                                    <Typography variant="body" component="p">{ data?.voucher_no || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Date</Typography>
                                    <Typography variant="body" component="p">{data?.date ? format(parse(data?.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Total</Typography>
                                    {data.total ? 
                                    <Typography variant="body" component="p">&#8377; {data?.total} </Typography> 
                                    :
                                    <Typography variant="body" component="p">-</Typography> 
                                    }
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        { data?.status == 1 && data?.approval == 0 &&
                            <Grid item xs={12} sx={{ px: 1, py:1, borderBottom: 1, borderColor: 'divider', }}>
                                <Typography variant="overline" component="p">Date</Typography>
                                <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                            </Grid>
                        }
                        
                        <Grid item xs={12} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.remarks ? data.remarks : '-'}</Typography>
                        </Grid>

                        {
                            data?.approval == 2
                            ?
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider',px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Rejection Reason</Typography>
                                <Typography variant="body" component="p">{reason ? reason : '-'}</Typography>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </Box>

                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir={data?.attachment_directory} images={data?.images} />
                </Box>

                {data?.status_label !== "Voided" &&
                <Box>
                {
                    portal == "buyer" && data?.approval == 0 &&
                    <Box sx={{display: "flex", gap:1, borderTop: 1, borderColor: "divider", paddingTop: "5px"}}>
                        <Box sx={{paddingLeft:1, flex: 1}}>
                            <Button variant="contained" fullWidth onClick={redirectOnClick}>Order Chat</Button>
                        </Box>
                        <Box sx={{paddingRight:1, flex: 1}}>
                            <UploadMedia 
                                id={data.id} module="entry"
                                dir="orders" link={url + 'api/media/add'}
                                refetch={refetch}
                            />
                        </Box>
                    </Box>
                }

                { (data?.status == 2 ||  data?.status_label ==="Ordered") &&
                    <Box sx={{  display: 'flex', justifyContent:"center", alignContent: "stretch", flexDirection: 'row', gap: 1, borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1 }}>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <Button variant="contained" sx={{height: "100%"}} fullWidth onClick={()=>{navigate("/order/view/" + data?.id)}} >View Order</Button>
                        </Box>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <Button variant="contained" sx={{height: "100%"}} fullWidth onClick={redirectOnClick} >Order Chat</Button>
                        </Box>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <Void styleObj={{height: "100%"}} refetch={refetch} id={data.id} type={ 'voucher' } voided={data.status != 3 ? 0 : 1 } />
                        </Box>                         
                    </Box>
                }
                { ( data?.approval == 2 || data?.status == 6 ||  data?.status_label ==="Completed") &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", gap:1}}>
                        {data?.status == 6 &&
                            <Box sx={{flex: 1}}>
                                <Button variant="contained" fullWidth onClick={()=>{navigate("/order/view/" + data?.id)}} >View Order</Button>
                            </Box>
                        }
                        <Box sx={{flex: 1}}>
                            <Button variant="contained" fullWidth onClick={redirectOnClick} >Order Chat</Button>
                        </Box>
                    </Box>
                }
                </Box>
                }
            </Paper>
        )
    };

    if(!filterDataBuyer && !filterDataSupplier){
        navigate(-1, { replace: true })
        return null;
    }

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>            
            {  isLoading ? 
            <LoadingComponent loading={isLoading} />
            : 
            <Layout title={title} backToTop={true}>                
                <Box>
                    <Alert severity="success" >
                        <AlertTitle sx={{fontSize: "0.5 em"}}> Results {portal === "buyer" ? filterDataSupplier && `for ${(filterDataSupplier?.name?.toUpperCase())}` : filterDataBuyer && `for ${(filterDataBuyer?.name?.toUpperCase())}`}</AlertTitle>
                        {(filterDataFromDate && filterDataToDate) && 
                        ` From: ${format(new Date(filterDataFromDate), 'dd/MM/yyyy')} To: ${format(new Date(filterDataToDate), 'dd/MM/yyyy')}`
                        }    
                        <Box sx={{mt: 1}}>
                        { filterDataStatus == "allData" ? `Status: ${currentTab.status[99]}`: `Status: ${currentTab.status[filterDataStatus]}`}
                        </Box>
                    </Alert>                    
                </Box>                
                <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                <BackToTop alone={true} />
            </Layout>
            }
            </>
        }
        </>
    )
}