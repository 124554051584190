import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Fab from '@mui/material/Fab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { IconPlus, IconPencil } from '@tabler/icons-react';
import { GlobalContext } from '../contexts/GlobalContext'
import 'filepond/dist/filepond.css'
import { useNavigate } from 'react-router-dom';
import heic2any from 'heic2any';
import { Alert, Snackbar, Typography } from '@mui/material';

registerPlugin(FilePondPluginFileRename);
registerPlugin(FilePondPluginFileValidateType);

function NewFormProduct(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, ledgers, portal  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [ledger, setLedger] = useState("")
    const [files, setFiles] = useState([])
    const [pond, setPond] = useState('')
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)
    const [sku, setSku] = useState("");
    const [name, setName] = useState("");
    const [size_combo, setSizeCombo] = useState("");
    const [fileList, setFileList] = useState([]);
    const [fileValidation, setFileValidation] = useState(true)

    const handleOpen = () => {
        setOpen(true);
        if(props.type == "Edit") {
            setSku(props.data.sku)
            setName(props.data.name)
            setSizeCombo(props.data.sizes)
        }
    };    
    const handleClose = () => {
        setOpen(false);
    };    

    const handleCamera = () => {
        setFileValidation(false);
        navigator.camera.getPicture(function (imageData) {
            // console.log(imageData);
            // console.log(pond);
            pond.addFile("data:image/jpg;base64," + imageData)
            .then((file) => {
                setFiles(prevFiles => [...prevFiles, file]);
                setFileValidation(true);
                // console.log("file", file);
            }).catch((error) => {
                setFileValidation(true);
                // Something went wrong
            });
        }, function (message) {
            setFileValidation(true);
            alert(message);
        }, {
            quality: 50,
            destinationType: Camera.DestinationType.DATA_URL,
            encodingType: Camera.EncodingType.JPEG,
            mediaType: Camera.MediaType.PICTURE,
            correctOrientation: true
        });
    };    
    
    const handleFile = () => {
        pond.browse();
    };

    const fileRenameFunction = (file) => {
        console.log("file", file);
        if(file.extension == file.name) {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}.jpg`;
        } else {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}${file.extension}`;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log("files", files);
        // var fileList = [];
        // files.forEach(function(f) {
        //     fileList.push({ file: f.filename, type: f.fileType, ext: f.fileExtension } );
        // })
        if(props.type == "New") {
            if(fileList.length == 0) {
                alert("Add one or more attachment to create entry");
                return false;
            }
            const fileNames = files.map( file =>  file.filename);
            axios.post( url + 'api/suppliers/chat/forms/products/add', {
                customer: customer,
                contact: contact,
                phone: phone,
                creator: portal == "supplier" ? 2 : 3,
                name: name,
                size_combo_id:  size_combo.id,
                sku: sku,
                form: props.form ? props.form : null,
                files: JSON.stringify(fileList.filter( f => fileNames.includes(f.file))),
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    setOpen(false);
                    setLoading(false);
                    // location.reload();
                    // navigate(0);
                    document.location.reload();
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        } else {
            axios.post( url + 'api/suppliers/chat/forms/products/update', {
                customer: customer,
                contact: contact,
                phone: phone,
                creator: portal == "supplier" ? 2 : 3,
                id: props.data.id,
                name: name,
                size_combo_id: size_combo.id,
                sku: sku,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    setOpen(false);
                    setLoading(false);
                    // location.reload();
                    // navigate(0);
                    document.location.reload();
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    };

    function CustomButton() {
        if(props.type === "Edit") {
            return (
                <Button size="small" onClick={handleOpen}><IconPencil /></Button>
            )
        } 
        else if(props.type === "edit_button"){
            return(
                <Button size="small" variant="outlined" color="primary" onClick={handleOpen} startIcon={<IconPencil />}> <Typography variant="caption" >Edit</Typography> </Button>
            )
        }
        else {
            return (
                <Fab color="primary" aria-label="add" onClick={handleOpen} size={"medium"} sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                    }}>
                    <IconPlus />
                </Fab>
            )
        }
    }

    const [errorMessage, setErrorMessage] = useState("");
    const [openErrorAlert, setOpenErrorAlert] = useState(false);    

    const ErrorMessage = () => {

        function handleClose(){
            setOpenErrorAlert(false)
            setErrorMessage('')
        }

        return(
            <>
            <Snackbar open={openErrorAlert}  anchorOrigin={{vertical: 'top', horizontal: 'center',}} 
                onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {errorMessage}
            </Alert>
            </Snackbar>
            </>
        )
    }

    function convertHEICToJPG(file) {
        return heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.6
        });
    }

    function getExtension(fileName) {
        if (fileName.includes('.')) {
            // Split the fileName by dot and return the last part
            return '.' + fileName.split('.').pop();
        } else {
            // If there's no dot, return an empty string indicating no extension
            return '';
        }
    }

    return (
        <div>
            <CustomButton></CustomButton>
            { props.type == "New" ?
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>New Product</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                    <form onSubmit={handleSubmit}>
                        
                        <InputLabel shrink>Name</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true}
                            value={name}
                            onChange={event => {
                                const { value } = event.target;
                                setName(value);
                            }} />
                        <InputLabel shrink>SKU</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth
                            value={sku}
                            onChange={event => {
                                const { value } = event.target;
                                setSku(value);
                            }} />

                        <InputLabel shrink>Size Combo</InputLabel>
                        <Autocomplete sx={{ mb: 2 }}
                            options={ props.sizes }
                            getOptionLabel={(option) => option.name ? option.name : '' }
                            value={size_combo}
                            onChange={(event, newValue) => {
                                setSizeCombo(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        
                        <InputLabel shrink>Attachments</InputLabel>
                        <Alert severity="info" sx={{mb:2}}>
                            <Typography variant="body2" sx={{fontSize: "12px"}}> Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv </Typography>
                        </Alert>
                        <Box sx={{ display: 'flex', justifyContent:"start", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                            { window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleCamera}>Camera</Button>
                            }
                            <Button variant="contained" size="medium" onClick={handleFile}>File</Button>
                        </Box>
                        <FilePond
                            acceptedFileTypes={[
                                "application/pdf",
                                "image/png", 
                                "image/jpg", 
                                "image/jpeg", 
                                "image/heic", 
                                'application/vnd.ms-excel',  // .xls
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                                'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
                                'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
                                "text/csv", //.csv
                                "application/vnd.oasis.opendocument.spreadsheet", //.ods
                                ""
                            ]}
                            ref={ref => ( setPond(ref) )}
                            credits={false}
                            files={files}
                            onupdatefiles={setFiles}
                            fileRenameFunction={ file => ( fileRenameFunction(file) )}
                            allowMultiple={false}
                            // server={ url + "api/pond/process"}
                            server={{
                                url: url,
                                revert: "./api/pond/process",
                                process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                    const allowedFiles = [".jpg", ".jpeg", ".heic", ".png", ".pdf", ".xls", ".xlsx", ".xlsm", ".xlsb", ".ods", ".csv" ]  
                                    if (fileValidation) {
                                        if(!allowedFiles.includes(getExtension(file?.name.toLowerCase()))){
                                            error('Unkown file extension')
                                            setOpenErrorAlert(true)
                                            setErrorMessage('Unsupported file type. Please upload files with the allowed extensions. Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv')
                                            abort()
                                        }
                                    } else {
                                        file.name = file.name + '.jpg';
                                    }
                                    try {
                                        let fn = file.name.toLowerCase()
                                        // Check if the file type is HEIC
                                        if (fn.indexOf('.heic') !== -1) {
                                            // Convert HEIC to JPG
                                            const convertedBlob = await convertHEICToJPG(file);
                                            // Create a new file object from the Blob
                                            const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), {
                                                type: "image/jpeg",
                                            });
                                            file = newFile; // Update the file reference
                                        }
                        
                                        // Now upload the file (either original or converted)
                                        // Example using fetch API
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                                        const response = await fetch(url + "api/pond/process", {
                                            method: 'POST',
                                            body: formData
                                        });
                        
                                        if (response.ok) {
                                            const data = await response.json();
                                            load(data.name);
                                            let currentFile = { file: data.name, type: data.type, ext: data.ext };
                                            setFileList(currentFiles => [...currentFiles, currentFile]);
                                        } else {
                                            error('Could not upload file');
                                        }
                                    } catch (err) {
                                        error(err.message);
                                    }}
                            }}
                            name="files" /* sets the file input name, it's filepond by default */
                            labelIdle='No files selected'
                            onaddfile={(error, file) => { 
                                if(!error){
                                    setLoading(true)
                                }
                            }}  
                            onprocessfiles={() => setLoading(false)}   
                          //  onprocessfilerevert={() => {setLoading(true)}}   
                            // onremovefile={() => {
                            //     setLoading(false); 
                            //     setOpenErrorAlert(false); 
                            //     setErrorMessage("")
                            // }} 
                            
                            onerror={(error, file) => {
                                console.log({error, file})
                                // const filesArray = files?.filter((item) => (item?.id !== file?.id));
                                // setFiles(filesArray)
                            }} 
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                    {openErrorAlert &&
                        <>
                        <ErrorMessage />
                        </>
                    }  
                </DialogContent>
            </Dialog>
            :
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Edit Product</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                    <form onSubmit={handleSubmit}>
                        <InputLabel shrink>Name</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true}
                            value={name}
                            onChange={event => {
                                const { value } = event.target;
                                setName(value);
                            }} />
                        <InputLabel shrink>SKU</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true}
                            value={sku}
                            onChange={event => {
                                const { value } = event.target;
                                setSku(value);
                            }} />

                        <InputLabel shrink>Size Combo</InputLabel>
                        <Autocomplete
                            options={ props.sizes }
                            getOptionLabel={(option) => option.name ? option.name : '' }
                            value={size_combo}
                            onChange={(event, newValue) => {
                                setSizeCombo(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            }
        </div>
    )
}
export default NewFormProduct;