import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { parseISO, format } from 'date-fns';
import DownloadPDF from "../../../components/DownloadPDF";
import DownloadExcel from "../../../components/DownloadExcel";
import { Box } from "@mui/system";
import NoData from "../../../components/NoData";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';



export default function DueReportData() {

    const { url,  customer, token, portal  } = useContext(GlobalContext);

    const title = `Due By ${portal === "buyer" ? "Supplier" : "Buyer"}`
    
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedFromDate, setSelectedFromDate] = useState()
    const [selectedToDate, setSelectedToDate] = useState()
    
    const tableRef = useRef();

    const printString = portal === "buyer" ? `Buyer : ${data?.buyer?.name}, ${data?.buyer?.city_name}` : `Supplier : ${data?.supplier?.name}, ${data?.supplier?.city_name}`;

    useEffect(() => {

        const fetchData = () => {
            if(portal === "buyer"){
                axios.post( url + "api/buyers/dueByBuyer/" + customer , {
                    buyer: portal === 'buyer' ? customer : "" ,
                    supplier: portal === 'supplier' ? customer : ""                
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    if ( !Object.hasOwn(response, 'data') ) throw new Error("Parse error");
                    if ( !Object.hasOwn(response.data, 'suppliers') ) throw new Error("Parse error");

                    setData(response.data);
                    setFilteredData(response.data.suppliers);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                })

            }else if(portal === "supplier"){

                axios.post( url + "api/suppliers/dueBySupplier/" + customer , {
                    buyer: portal === 'buyer' ? customer : "" ,
                    supplier: portal === 'supplier' ? customer : ""                
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    if ( !Object.hasOwn(response, 'data') ) throw new Error("Parse error");
                    if ( !Object.hasOwn(response.data, 'buyers') ) throw new Error("Parse error");

                    setData(response.data);
                    setFilteredData(response.data.buyers);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                })

            }
        };

        fetchData();

    }, []);
    
    const filterSelectedCity = (customer)=>{
        if ( !selectedCity ) return true;
        return (selectedCity === customer?.city_name);
    };

    const filterFromDate = (invoice)=>{
        if (!selectedFromDate) return true;
        return selectedFromDate.isSame(dayjs(invoice.date)) || selectedFromDate.isBefore(dayjs(invoice.date));
    };

    const filtertoDate = (invoice)=>{
        if (!selectedToDate) return true;
        return selectedToDate.isSame(dayjs(invoice.date)) || selectedToDate.isAfter(dayjs(invoice.date));
    };

    const filterDates = (customer)=>{
        customer = {...customer};
        switch (portal) {
            case "buyer":
                customer.seller_invoices = customer.seller_invoices.filter(filterFromDate).filter(filtertoDate);
                break;
            case "supplier":
                customer.buyer_invoices = customer.buyer_invoices.filter(filterFromDate).filter(filtertoDate);
                break;
        }

        return customer;
    };

    useEffect(() => {
        if (!data) return;

        if(portal === "buyer"){
            if(!data?.suppliers?.length) throw new Error("Parse error");
            
            const filteredSuppliers = data.suppliers
                .filter(filterSelectedCity)
                .map(filterDates)
                .filter((supplier) => supplier.seller_invoices.length > 0 );

            setFilteredData(filteredSuppliers);
        }else
        
        if(portal === "supplier"){
            if(!data?.buyers?.length) throw new Error("Parse error");
            
            const filteredBuyers = data.buyers
                    .filter(filterSelectedCity)
                    .map(filterDates)
                    .filter((buyer) => buyer.buyer_invoices.length > 0 );

            setFilteredData(filteredBuyers);
        }
    }, [selectedCity, selectedFromDate, selectedToDate ])

    const TableGrandTotal = ({data}) => {

        function calculateTotalDue(item) {
            return data.reduce((totalDue, item) => {
                if (portal === "buyer" && item?.seller_invoices?.length) {
                    const sellerTotal = item.seller_invoices.reduce((acc, invoice) => {
                        const due = JSON.parse(invoice?.summary)?.due || 0;
                        return acc + due;
                    }, 0);
                    return totalDue + sellerTotal;
                } else if (portal === "supplier" && item?.buyer_invoices?.length) {
                    const buyerTotal = item.buyer_invoices.reduce((acc, invoice) => {
                        const due = JSON.parse(invoice?.summary)?.due || 0;
                        return acc + due;
                    }, 0);
                    return totalDue + buyerTotal;
                }
                return totalDue;
            }, 0);
        }

        function calculateTotalPaid(item) {
            return data.reduce((totalPaid, item) => {
                if (portal === "buyer" && item?.seller_invoices?.length) {
                    const sellerTotal = item.seller_invoices.reduce((acc, invoice) => {
                        const paid = JSON.parse(invoice?.summary)?.paid || 0;
                        return acc + paid;
                    }, 0);
                    return totalPaid + sellerTotal;
                } else if (portal === "supplier" && item?.buyer_invoices?.length) {
                    const buyerTotal = item.buyer_invoices.reduce((acc, invoice) => {
                        const paid = JSON.parse(invoice?.summary)?.paid || 0;
                        return acc + paid;
                    }, 0);
                    return totalPaid + buyerTotal;
                }
                return totalPaid;
            }, 0);
        }

        function calculateGrandTotal(item) {
            return data.reduce((grandTotal, item) => {
                if (portal === "buyer" && item?.seller_invoices?.length) {
                    const sellerTotal = item.seller_invoices.reduce((acc, invoice) => {
                        const total = +invoice?.total
                        return acc + total;
                    }, 0);
                    return grandTotal + sellerTotal;
                } else if (portal === "supplier" && item?.buyer_invoices?.length) {
                    const buyerTotal = item.buyer_invoices.reduce((acc, invoice) => {
                        const total = +invoice?.total
                        return acc + total;
                    }, 0);
                    return grandTotal + buyerTotal;
                }
                return grandTotal;
            }, 0);
        }

        return(
            <>
                <TableRow>
                    <TableCell algin="left" sx={{fontWeight: "bold"}}>Grand Total</TableCell>
                    <TableCell colSpan={3} ></TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateGrandTotal(data)}</TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateTotalPaid(data)}</TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateTotalDue(data)}</TableCell>
                </TableRow>
            </>
        );
    }

    const TableBodyComponent = ({item}) => {

        function calculateTotalDue(item) {
            if(portal === "buyer" && item?.seller_invoices?.length !== 0 ){
                const total = item?.seller_invoices?.reduce((acc, item) => {
                    const due = JSON.parse(item?.summary)?.due;
                    acc = acc + due;
                    return acc;
                }, 0)

                return total
            }else if(portal === "supplier" && item?.buyer_invoices?.length !== 0){
                const total = item?.buyer_invoices?.reduce((acc, item) => {
                    const due = JSON.parse(item?.summary)?.due;
                    acc = acc + due;
                    return acc;
                }, 0)

                return total
            }
        }

        function calculateTotalPaid(item) {
            if(portal === "buyer" && item?.seller_invoices?.length !== 0 ){
                const total = item?.seller_invoices?.reduce((acc, item) => {
                    const paid = JSON.parse(item?.summary)?.paid
                    acc = acc + paid;
                    return acc;
                }, 0)

                return total
            }else if(portal === "supplier" && item?.buyer_invoices?.length !== 0){
                const total = item?.buyer_invoices?.reduce((acc, item) => {
                    const paid = JSON.parse(item?.summary)?.paid
                    acc = acc + paid;
                    return acc;
                }, 0)

                return total
            }
        }

        function calculateGrandTotal(item) {
            if(portal === "buyer" && item?.seller_invoices?.length !== 0 ){
                const total = item?.seller_invoices?.reduce((acc, item) => {
                    const grandTotal = +item?.total
                    acc = acc + grandTotal;
                    return acc;
                }, 0)

                return total
            }else if(portal === "supplier" && item?.buyer_invoices?.length !== 0){
                const total = item?.buyer_invoices?.reduce((acc, item) => {
                    const grandTotal = +item?.total
                    acc = acc + grandTotal;
                    return acc;
                }, 0)

                return total
            }
        }

        function termsAndCondition(parsedData) {
            
            const dueDiscount = parsedData?.due_discount ? parsedData?.due_discount : "0.00";
            const nettDiscount = parsedData?.nett_discount ? parsedData?.nett_discount : "0.00";
            
            const dueStatus = parsedData?.due_date && parsedData.due_date.trim() !== "";
            const nettStatus = parsedData?.nett_date && parsedData.nett_date.trim() !== "";
            
            const supplierDueStatus = parsedData?.due_status == 1;
            const supplierNettStatus = parsedData?.nett_status == 1;

            let html = "";
            if(dueStatus){
                const dueDate = parsedData?.due_date;
                const differenceInDays = Math.abs(dayjs(parsedData?.date).diff(dueDate, 'day', true));
                html += `Due: ${differenceInDays} days >> ${dueDiscount} %<br>`;
            }else if(supplierDueStatus && isset(parsedData?.due_days)){
                html += `Due: ${parsedData?.due_days} days >> ${parsedData?.due_discount || "0.00"} %<br>`;
            }
            
            if(nettStatus){
                const nettDate = parsedData?.nett_date;
                const differenceInDays = Math.abs(dayjs(parsedData?.date).diff(nettDate, 'day', true));
                html += `Due: ${differenceInDays} days >> ${nettDiscount} %`;
            }else if(supplierNettStatus && isset(parsedData?.nett_date)){
                html += `Due: ${parsedData?.nett_days} days >> ${parsedData?.nett_discount || "0.00"} %`;
            }

            return <span dangerouslySetInnerHTML={{ __html: html }} />;
        }

        const TableRowData = ({rowItem, index}) => {

            const parsedData = JSON.parse(rowItem?.summary || "{}");

            return(
                <>
                <TableRow>
                    <TableCell align="left">{index+1}</TableCell>
                    <TableCell align="left">{rowItem?.voucher_no}</TableCell>
                    <TableCell align="left">{format(parseISO(rowItem?.date), 'dd MMM yyyy')}</TableCell>
                    <TableCell align="left">{parsedData ? termsAndCondition(parsedData) : '-'}</TableCell>
                    <TableCell align="right">{+rowItem?.total}</TableCell>
                    <TableCell align="right">{parsedData?.paid}</TableCell>
                    <TableCell align="right">{parsedData?.due}</TableCell>
                </TableRow>
                </>
            )
        }

        const badgeStyle = { 
            fontSize: '12px', 
            padding: '5px 10px', 
            backgroundColor: '#f0f0f0', 
            borderRadius: '12px', 
            display: portal === 'buyer' ? 'inline-block' : 'hidden', 
            position: 'relative'
        };

        return(
            <>            
                <TableRow>
                    <TableCell colSpan={7} sx={{fontWeight: "bold"}}>
                        {portal === "buyer" ? "Supplier" : "Buyer"} : {item?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{item?.city_name}</span>
                        {
                            item.msme &&
                            <span style={{...badgeStyle, marginLeft: "10px"}}>{item.msme}</span>
                        }
                    </TableCell>
                </TableRow>
                <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                    <TableCell>S.No.</TableCell>
                    <TableCell>Invoice No.</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Terms & Condition</TableCell>
                    <TableCell align="right">Total (Rs.)</TableCell>
                    <TableCell align="right">Paid (Rs.)</TableCell>
                    <TableCell align="right">Due (Rs.)</TableCell>
                </TableRow>
                
                { portal === "buyer" ? 
                    (item?.seller_invoices?.length !== 0 && item?.seller_invoices?.map((rowItem, index) => (<TableRowData key={index}  rowItem={rowItem} index={index}  />)))
                        :
                    (item?.buyer_invoices?.length !== 0 && item?.buyer_invoices?.map((rowItem, index) => (<TableRowData key={index} rowItem={rowItem} index={index}  />)))
                }

                <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                    <TableCell colSpan={4} ></TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateGrandTotal(item)}</TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateTotalPaid(item)}</TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateTotalDue(item)}</TableCell>
                </TableRow>
            
            </>
        )
    }

    const PrintedTable = ({listData}) => {
        return (
            <TableContainer component={Paper} sx={{mb:2}}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                            <TableCell sx={{fontWeight: "bold"}}>S.No.</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Invoice No.</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Date</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Terms & Condition</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Total (Rs.)</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Paid (Rs.)</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Due (Rs.)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listData.map((item, index) => (<TableBodyComponent key={index} item={item} />)) }                                              
                        <TableGrandTotal data={listData}/>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const FilterComponent = () => {
        
        const [options, setOptions] = useState([]);

        useEffect(() => {
            if(portal === "buyer"){
                const filteredCities = data?.suppliers.reduce((value, item) => {
                    !value.includes(item?.city_name) && value.push(item?.city_name)
                    return value
                }, []);

                setOptions(filteredCities)
                
            }else if(portal === "supplier"){
                const filteredCities = data?.buyers.reduce((value, item) => {
                    !value.includes(item?.city_name) && value.push(item?.city_name)
                    return value
                }, []);

                setOptions(filteredCities)
            }
        }, [])   

        return(
            <>
            <Autocomplete sx={{ my: 3 }}
                options={options}
                size="small"
                value={selectedCity}
                onChange={(event, newValue) => {
                    newValue === null ? setSelectedCity("") : setSelectedCity(newValue)
                }}
                renderInput={(params) => <TextField {...params} required={false} label={"Filter by city"} />}
                isOptionEqualToValue={(option, value) => { 
                    if(!value){
                        return true
                    }else{
                        return option === value
                    }
                }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: "flex", maxWidth: "500px", gap: "10px", flexWrap: "wrap" }}>
                    <div style={{ flexGrow: "1" }}>
                        <InputLabel shrink>From Date </InputLabel>
                        <DatePicker sx={{ mb: 2, width: "100%" }}
                            timezone='Asia/Kolkata'
                            value={selectedFromDate} 
                            onChange={  (newDate) => setSelectedFromDate(newDate)  }
                            format={'DD/MM/YYYY'}
                            maxDate={selectedToDate ? selectedToDate : ""}
                        />
                    </div>
                    <div style={{ flexGrow: "1" }}>
                        <InputLabel shrink>To Date </InputLabel>
                        <DatePicker sx={{ mb: 2, width: "100%" }}
                            timezone='Asia/Kolkata'
                            value={selectedToDate}
                            onChange={  (newDate) =>  setSelectedToDate(newDate)    }
                            format={'DD/MM/YYYY'}
                            minDate={selectedFromDate ? selectedFromDate : ""}
                        />
                    </div>
                </div>

            </LocalizationProvider>
            </>
        )
    }
    
    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <Layout title={title}>
                
                {portal === "buyer" ? 
                
                (data?.suppliers?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} />
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <FilterComponent />
                    <PrintedTable listData={filteredData} />
                </> : <NoData />
                )

                :

                (data?.buyers?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} />
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <FilterComponent />
                    <PrintedTable listData={filteredData} />
                </> 
                : <NoData /> 
                )

                }
                
            </Layout>
        } 
        </>
    )
}
