import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import UploadButton from '../../components/VoucherUploader';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns'

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { red, amber, green } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import MediaGrid from '../../components/MediaGrid';
import TicketsGlobal from '../../components/TicketsGlobal';
import LoadingComponent from '../../components/LoadingComponent';

function CreditNote() {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Credit Note View");
        }
    }, [ ]);

    const fetchData = () => {
        axios.post( url + 'api/voucher', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setData(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Info = () => {
        if (data.hasOwnProperty('voucher')) { 
        return (
            <React.Fragment>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="h6" component="h6">{ portal == "supplier" ? data.voucher.buyer_name : data.voucher.seller_name }</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">CN #</Typography>
                            <Typography variant="body" component="p">{data.voucher.voucher_no ? data.voucher.voucher_no : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Date</Typography>
                            <Typography variant="body" component="p">{data.voucher.date ? format(parse(data.voucher.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377; {data.voucher.total ? data.voucher.total : ''}</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ borderColor: 'divider', width: '100%' }}>
                        <MediaGrid dir={data.voucher.attachment_directory} images={data.voucher.images} />
                    </Box>
                </Paper>
            </React.Fragment>
        )
        } else {
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                    <Typography variant="overline" component="p">Loading...</Typography>
                </Paper>
            )
        }
    }

    const ItemRow = (props) => {
        
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
          <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <IconChevronUp /> : <IconChevronDown />}</IconButton>
              </TableCell>
              <TableCell align="left">{row.sno}</TableCell>
              <TableCell align="left">{row.particular}</TableCell>
              <TableCell align="right">{row.rate}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">Details</Typography>

                    <Grid container>
                        {
                            JSON.parse(row.attributes).length > 0 &&
                            JSON.parse(row.attributes).map( attribute => {
                                return (
                                    <>
                                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                                            <Typography variant="overline" component="p">{attribute.name}</Typography>
                                            <Typography variant="body" component="p">{attribute.value}</Typography>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount (%)</Typography>
                            <Typography variant="body" component="p">{row.discount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount (&#8377;)</Typography>
                            <Typography variant="body" component="p">&#8377;{row.discount_amount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Tax (%)</Typography>
                            <Typography variant="body" component="p">{row.tax}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377;{row.subtotal}</Typography>
                        </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
    }

    const Items = () => {
        // console.log("data.voucher.items", data.voucher.items)
        return (
            <Box>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table stickyHeader aria-label="collapsible table">
                        <TableHead>
                        <TableRow>
                            <TableCell width="30px" />
                            <TableCell width="50px" align="left" size="small">#</TableCell>
                            <TableCell align="left">Particular</TableCell>
                            <TableCell align="right">Rate</TableCell>
                            <TableCell align="right">Qty</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            data.voucher.clients_voucher_items.map((row) => (
                                <ItemRow key={row.sno} row={row} />
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Credit Note">
                <Info />
                <Items />
                <TicketsGlobal seller={data.voucher.seller} buyer={data.voucher.buyer} area="clients_vouchers" id={data.voucher.id} />
            </Layout>
        }
        </div>
    );
}

export default CreditNote;